
@mixin spriteImage{
    background-image: url("../img/sprite.svg");
    background-repeat: no-repeat;
}

@mixin sprite-footer-logo-mob($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -429.17999999999995px;
    width: 20px;
    height: 16px;
}
@mixin sprite-footer-logo-tab($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -386px -372px;
    width: 31px;
    height: 26px;
}
@mixin sprite-header-logo-desk($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -417px -372px;
    width: 30px;
    height: 25px;
}
@mixin sprite-header-logo-desk_round($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -198px -306px;
    width: 53px;
    height: 53px;
}
@mixin sprite-header-logo-mob($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -97px -372px;
    width: 33px;
    height: 33px;
}
@mixin sprite-header-logo-tab($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -342px -66px;
    width: 66px;
    height: 66px;
}
@mixin sprite-ico-arrow-back-mob($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -491px -147px;
    width: 12px;
    height: 19px;
}
@mixin sprite-ico-arrow-back-mob-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -491px -195px;
    width: 12px;
    height: 19px;
}
@mixin sprite-ico-arrow-back-mob2($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: 0 -429px;
    width: 18px;
    height: 29px;
}
@mixin sprite-ico-arrow-back-mob2-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -321px -247px;
    width: 18px;
    height: 29px;
}
@mixin sprite-ico-arrow-back-tab($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -219px;
    width: 14px;
    height: 23px;
}
@mixin sprite-ico-arrow-back-tab-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -242px;
    width: 14px;
    height: 23px;
}
@mixin sprite-ico-arrow-more-mob($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -492px -99px;
    width: 11px;
    height: 19px;
}
@mixin sprite-ico-arrow-more-mob-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -492px -75px;
    width: 11px;
    height: 19px;
}
@mixin sprite-ico-arrow-more-mob2($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -18px -429px;
    width: 17px;
    height: 29px;
}
@mixin sprite-ico-arrow-more-mob2-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -35px -429px;
    width: 17px;
    height: 29px;
}
@mixin sprite-ico-arrow-more-tab($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -75px;
    width: 14px;
    height: 24px;
}
@mixin sprite-ico-arrow-more-tab-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -99px;
    width: 14px;
    height: 24px;
}
@mixin sprite-ico-bell-mob-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -287.09px;
    width: 18.84px;
    height: 22.09px;
}
@mixin sprite-ico-bell-mob-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -265px;
    width: 18.84px;
    height: 22.09px;
}
@mixin sprite-ico-bell-tab-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -292px -247px;
    width: 29px;
    height: 34px;
}
@mixin sprite-ico-bell-tab-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: 0 -372px;
    width: 29px;
    height: 34px;
}
@mixin sprite-ico-carousel-arrow-next-mob($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -492px -242px;
    width: 8px;
    height: 15px;
}
@mixin sprite-ico-carousel-arrow-next-mob2($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -315.25px -180px;
    width: 16px;
    height: 29px;
}
@mixin sprite-ico-carousel-arrow-next-tab($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -195px;
    width: 13px;
    height: 24px;
}
@mixin sprite-ico-carousel-arrow-prev-mob($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -492px -219px;
    width: 8px;
    height: 15px;
}
@mixin sprite-ico-carousel-arrow-prev-mob2($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -382px -264px;
    width: 16px;
    height: 29px;
}
@mixin sprite-ico-carousel-arrow-prev-tab($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -147px;
    width: 13px;
    height: 24px;
}
@mixin sprite-ico-checkbox-mob-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -351.17999999999995px;
    width: 22px;
    height: 22px;
}
@mixin sprite-ico-clip-mob-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -373.17999999999995px;
    width: 22px;
    height: 14px;
}
@mixin sprite-ico-clip-mob2-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -408px -208px;
    width: 43px;
    height: 27px;
}
@mixin sprite-ico-clip-tab-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -292px -226px;
    width: 34px;
    height: 21px;
}
@mixin sprite-ico-like-mob-deepgrey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -460px -97px;
    width: 16px;
    height: 15px;
}
@mixin sprite-ico-like-mob-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -460px -112px;
    width: 16px;
    height: 15px;
}
@mixin sprite-ico-like-mob-white($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -460px -127px;
    width: 16px;
    height: 15px;
}
@mixin sprite-ico-like-mob-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -460px -82px;
    width: 16px;
    height: 15px;
}
@mixin sprite-ico-like-tab-deepgrey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -330.17999999999995px;
    width: 22px;
    height: 21px;
}
@mixin sprite-ico-like-tab-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -309.17999999999995px;
    width: 22px;
    height: 21px;
}
@mixin sprite-ico-like-tab-white($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -408.17999999999995px;
    width: 22px;
    height: 21px;
}
@mixin sprite-ico-like-tab-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -387.17999999999995px;
    width: 22px;
    height: 21px;
}
@mixin sprite-ico-lock-desk-closed($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -252px 0;
    width: 90px;
    height: 90px;
}
@mixin sprite-ico-lock-desk-opened($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -252px -90px;
    width: 90px;
    height: 90px;
}
@mixin sprite-ico-lock-mob-closed($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: 0 -226px;
    width: 80px;
    height: 80px;
}
@mixin sprite-ico-lock-mob-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -460px 0;
    width: 18px;
    height: 25px;
}
@mixin sprite-ico-lock-mob-opened($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -80px -226px;
    width: 80px;
    height: 80px;
}
@mixin sprite-ico-lock-mob-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -460px -25px;
    width: 18px;
    height: 25px;
}
@mixin sprite-ico-lock-mob2-closed($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: 0 0;
    width: 126px;
    height: 126px;
}
@mixin sprite-ico-lock-mob2-opened($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -126px 0;
    width: 126px;
    height: 126px;
}
@mixin sprite-ico-lock-tab-closed($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: 0 -126px;
    width: 100px;
    height: 100px;
}
@mixin sprite-ico-lock-tab-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -427px -235px;
    width: 28px;
    height: 38px;
}
@mixin sprite-ico-lock-tab-opened($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -100px -126px;
    width: 100px;
    height: 100px;
}
@mixin sprite-ico-lock-tab-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -252px -180px;
    width: 28px;
    height: 38px;
}
@mixin sprite-ico-quote-info-mob-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -448px -277px;
    width: 12px;
    height: 28px;
}
@mixin sprite-ico-quote-info-mob2-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -408px -235px;
    width: 19px;
    height: 42px;
}
@mixin sprite-ico-quote-mob-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -408px -357px;
    width: 19px;
    height: 15px;
}
@mixin sprite-ico-quote-tab-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: 0 -406px;
    width: 29px;
    height: 23px;
}
@mixin sprite-ico-reg-soc-fb-desk-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -52px -429px;
    width: 26px;
    height: 26px;
}
@mixin sprite-ico-reg-soc-fb-desk-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -208px -429px;
    width: 26px;
    height: 26px;
}
@mixin sprite-ico-reg-soc-fb-mob-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -130px -372px;
    width: 32px;
    height: 32px;
}
@mixin sprite-ico-reg-soc-fb-mob-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -290px -372px;
    width: 32px;
    height: 32px;
}
@mixin sprite-ico-reg-soc-fb-mobx-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: 0 -306px;
    width: 66px;
    height: 66px;
}
@mixin sprite-ico-reg-soc-fb-mobx-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -66px -306px;
    width: 66px;
    height: 66px;
}
@mixin sprite-ico-reg-soc-fb-tab-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -251px -306px;
    width: 52px;
    height: 52px;
}
@mixin sprite-ico-reg-soc-fb-tab-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -408px -156px;
    width: 52px;
    height: 52px;
}
@mixin sprite-ico-reg-soc-gp-desk-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -234px -429px;
    width: 26px;
    height: 26px;
}
@mixin sprite-ico-reg-soc-gp-desk-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -130px -429px;
    width: 26px;
    height: 26px;
}
@mixin sprite-ico-reg-soc-gp-mob-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -162px -372px;
    width: 32px;
    height: 32px;
}
@mixin sprite-ico-reg-soc-gp-mob-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -322px -372px;
    width: 32px;
    height: 32px;
}
@mixin sprite-ico-reg-soc-gp-mobx-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -132px -306px;
    width: 66px;
    height: 66px;
}
@mixin sprite-ico-reg-soc-gp-mobx-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -342px 0;
    width: 66px;
    height: 66px;
}
@mixin sprite-ico-reg-soc-gp-tab-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -303px -306px;
    width: 52px;
    height: 52px;
}
@mixin sprite-ico-reg-soc-gp-tab-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -355px -306px;
    width: 52px;
    height: 52px;
}
@mixin sprite-ico-reg-soc-ok-desk-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -156px -429px;
    width: 26px;
    height: 26px;
}
@mixin sprite-ico-reg-soc-ok-desk-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -182px -429px;
    width: 26px;
    height: 26px;
}
@mixin sprite-ico-reg-soc-ok-mob-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -258px -372px;
    width: 32px;
    height: 32px;
}
@mixin sprite-ico-reg-soc-ok-mob-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -226px -372px;
    width: 32px;
    height: 32px;
}
@mixin sprite-ico-reg-soc-ok-mobx-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -342px -198px;
    width: 66px;
    height: 66px;
}
@mixin sprite-ico-reg-soc-ok-mobx-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -342px -132px;
    width: 66px;
    height: 66px;
}
@mixin sprite-ico-reg-soc-ok-tab-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -408px -52px;
    width: 52px;
    height: 52px;
}
@mixin sprite-ico-reg-soc-ok-tab-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -200px -126px;
    width: 52px;
    height: 52px;
}
@mixin sprite-ico-reg-soc-vk-desk-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -104px -429px;
    width: 26px;
    height: 26px;
}
@mixin sprite-ico-reg-soc-vk-desk-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -78px -429px;
    width: 26px;
    height: 26px;
}
@mixin sprite-ico-reg-soc-vk-mob-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -194px -372px;
    width: 32px;
    height: 32px;
}
@mixin sprite-ico-reg-soc-vk-mob-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -354px -372px;
    width: 32px;
    height: 32px;
}
@mixin sprite-ico-reg-soc-vk-mobx-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -226px -226px;
    width: 66px;
    height: 66px;
}
@mixin sprite-ico-reg-soc-vk-mobx-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -160px -226px;
    width: 66px;
    height: 66px;
}
@mixin sprite-ico-reg-soc-vk-tab-color($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -408px -104px;
    width: 52px;
    height: 52px;
}
@mixin sprite-ico-reg-soc-vk-tab-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -408px 0;
    width: 52px;
    height: 52px;
}
@mixin sprite-ico-search-desk-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -460px -142px;
    width: 15px;
    height: 15px;
}
@mixin sprite-ico-search-header-desk-grey-close($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -310px -429px;
    width: 25px;
    height: 24px;
}
@mixin sprite-ico-search-header-mob-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -50px;
    width: 25px;
    height: 25px;
}
@mixin sprite-ico-search-header-mob-grey-close($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -25px;
    width: 25px;
    height: 25px;
}
@mixin sprite-ico-search-header-mob-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px 0;
    width: 25px;
    height: 25px;
}
@mixin sprite-ico-search-header-tab-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -408px -277px;
    width: 40px;
    height: 40px;
}
@mixin sprite-ico-search-header-tab-grey-close($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -408px -317px;
    width: 40px;
    height: 40px;
}
@mixin sprite-ico-search-header-tab-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -342px -264px;
    width: 40px;
    height: 40px;
}
@mixin sprite-ico-search-mob-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -460px -50px;
    width: 17px;
    height: 16px;
}
@mixin sprite-ico-search-mob-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -460px -66px;
    width: 17px;
    height: 16px;
}
@mixin sprite-ico-search-tab-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -285px -429px;
    width: 25px;
    height: 24px;
}
@mixin sprite-ico-search-tab-yellow($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -260px -429px;
    width: 25px;
    height: 24px;
}
@mixin sprite-ico-soc-ig-desk-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -29px -372px;
    width: 34px;
    height: 34px;
}
@mixin sprite-ico-soc-ig-mob-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -171px;
    width: 24px;
    height: 24px;
}
@mixin sprite-ico-soc-ig-tab-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -200px -178px;
    width: 36px;
    height: 36px;
}
@mixin sprite-ico-soc-vk-desk-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -63px -372px;
    width: 34px;
    height: 34px;
}
@mixin sprite-ico-soc-vk-mob-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -478px -123px;
    width: 24px;
    height: 24px;
}
@mixin sprite-ico-soc-vk-tab-grey($type: false) {
    @if ( $type == true ){
        @include spriteImage;
    }
    background-position: -280px -180px;
    width: 35.25px;
    height: 36px;
}


