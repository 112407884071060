.search{
	display: inline-block;
	vertical-align: middle;
	position: relative;
	float: right;
	margin: 5px 25px 0 0;

	@include breakPoint($breakPointMob, $breakPointTab1 - 1px){
		margin: 12px 30px 0 0;
	}

	@include breakPoint($breakPointTab1){
		width: calc(100% - 440px);
	}

	@include breakPoint($breakPointDesk1){
		width: 125px;
		float: right;
		margin: 19px 0 0 0;
	}

	&.is-open{
		position: static;


		@include breakPoint($breakPointDesk1){
			width: 25px;
		}

		.search__input{

		}
	}

	&__input{
		display: none;

		@include breakPoint($breakPointTab1){
			display: block;
		}

		@include breakPoint($breakPointDesk1){

			&:hover{
				& ~ {

				}
			}
		}

		input{
			height: 100%;
			display: block;
			@include box-sizing();
			max-width: 100%;

			@include breakPoint($breakPointTab1){
				display: block;
			}

			@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
				background: #fff;
				border: 1px solid $colorBorder;
				@include border-radius(100px);
				@include box-sizing();
				height: 52px;
				line-height: 50px;
				padding: 0 60px 0 30px;
				width: 100%;
			}

			.is-open &{
				margin: 0 15px;
				padding: 0;
				border: 0;
				width: calc(100% - 30px);
				border-bottom: 2px solid $colorYellow;
				height: 100%;
				@include border-radius(0);

				@include breakPoint($breakPointMob){
					margin: 0 30px;
					width: calc(100% - 60px);
					font-size: 20px;
				}

				@include breakPoint($breakPointTab1){
					margin: 0 24px;
					width: calc(100% - 48px);
					font-size: 20px;
				}

				@include breakPoint($breakPointDesk1){
					margin: 0 auto;
					width: $innerDesk1;
					font-size: 16px;
				}

				@include breakPoint($breakPointDesk2){
					width: $innerDesk2;
				}

				@include breakPoint($breakPointDesk3){
					width: $innerDesk3;
				}
			}
		}

		.is-open &{
			background: #fff;
			display: block;
			position: absolute;
			top: -8px;
			left: -15px;
			right: -15px;
			bottom: -8px;

			@include breakPoint($breakPointMob, $breakPointDesk1 - 1px){
				top: -20px;
				left: -30px;
				right: -30px;
				bottom: -20px;
			}

			@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
				left: -24px;
				right: -24px;
			}

			@include breakPoint($breakPointDesk1){
				top: 0px;
				left: 0px;
				right: 0px;
				bottom: 0px;
			}
		}
	}


	&__ico{
		@include sprite-ico-search-header-mob-grey(true);

		@include breakPoint($breakPointMob, $breakPointTab1 - 1px){
			@include sprite-ico-search-header-tab-grey;
		}

		@include breakPoint($breakPointTab1){
			top: 13px;
			right: 18px;
			position: absolute;
		}

		@include breakPoint($breakPointDesk1){
			right: auto;
			left: -30px;
			top: 1px;
			@include sprite-ico-search-desk-grey;
		}

		.is-open &{
			@include sprite-ico-search-header-mob-yellow;
			top: 5px;
			left: auto;
			right: 0;
			position: absolute;

			@include breakPoint($breakPointMob){
				top: 12px;
				@include sprite-ico-search-header-tab-yellow;
			}

			@include breakPoint($breakPointDesk1){
				top: auto;
				right: auto;
				left: auto;
				margin-top: -4px;
				@include sprite-ico-search-tab-yellow;
				margin-left: -65px;

				&:after{
					content: 'Найти';
					font-size: 16px;
					color: $colorYellow;
					margin-left: 40px;
					display: inline-block;
					margin-top: 2px;
				}
			}
		}

		.is-open.is-fill &{
			@include sprite-ico-search-header-mob-grey-close;

			@include breakPoint($breakPointMob){
				@include sprite-ico-search-header-tab-grey-close;
			}

			@include breakPoint($breakPointDesk1){
				@include sprite-ico-search-header-desk-grey-close;
				margin-left: 0;

				&:after{
					display: none;
				}
			}
		}
	}
}


.header{
	position: relative;
	@include transition(background 0.3s ease);
	z-index: 11;

	.wrapper_inside &{
		border-bottom: 1px solid $colorBorder;
	}

	.is-menu-open &{
		background: #fff;
	}

	.inner{
		padding: 8px 0;

		@include breakPoint(0, $breakPointDesk1 - 1px){
			margin: 0;
			width: auto;
		}

		@include breakPoint($breakPointMob){
			padding: 20px 0;
		}

		@include breakPoint($breakPointDesk1){
			//padding: 30px 0 10px;

			&:after{
				content: '';
				display: table;
				clear: both;
			}
		}
	}

	&__control{

		@include breakPoint(0, $breakPointDesk1 - 1px){
			position: relative;
			z-index: 1;
		}

		@include breakPoint($breakPointDesk1){
			display: inline;
		}

		&-inner{
			margin: 0 15px;
			position: relative;

			@include breakPoint($breakPointMob){
				margin: 0 30px;
			}

			@include breakPoint($breakPointTab1){
				margin: 0 24px;
			}

			@include breakPoint($breakPointTab2){
			}

			@include breakPoint($breakPointDesk1){
				margin: 0;
				position: static;
				display: inline;
			}
		}

		.header__part{
			display: none;

			@include breakPoint($breakPointDesk1){
				display: inline-block;
			}
		}
	}

	&__content{

		@include breakPoint(0, $breakPointDesk1 - 1px){
			position: absolute;
			background: #fff;
			top: -100vh;
			left: 0;
			right: 0;
			max-height: 100vh;
			overflow: auto;
			@include transition(top 0.3s ease);

			.is-menu-open &{
				top: 100%;
			}
		}

		@include breakPoint($breakPointDesk1){
			display: inline;
		}

		@include breakPoint($breakPointDesk2){
			margin-left: 70px;
		}

		&-inner{
			margin: 0 auto;
			width: 245px;
			padding-top: 10px;

			@include breakPoint($breakPointMob){
				width: 495px;
				padding: 30px 0 70px;
			}

			@include breakPoint($breakPointTab1){
				width: 459px;
			}

			@include breakPoint($breakPointTab2){

			}

			@include breakPoint($breakPointDesk1){
				margin: 0;
				padding: 0;
				width: auto;
				display: inline;
			}
		}
	}

	&__logo{
		white-space: nowrap;
		display: inline-block;
		vertical-align: middle;

		@include breakPoint($breakPointDesk1){

		}

		@include breakPoint($breakPointDesk2){
			//margin-right: 70px;
		}

		&-img{
			display: inline-block;
			vertical-align: middle;
			@include sprite-header-logo-mob(true);
			margin-right: 5px;

			@include breakPoint($breakPointMob){
				@include sprite-header-logo-tab;
				margin-right: 15px;
			}

			@include breakPoint($breakPointDesk1){
				@include sprite-header-logo-desk;
			}
		}

		&-text{
			display: inline-block;
			vertical-align: middle;
			font-size: 15px;
			font-weight: 500;
			color: $colorBlack;

			@include breakPoint($breakPointMob){
				font-size: 30px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 28px;
			}
		}
	}

	&__nav{
		text-align: center;
		font-size: 16px;

		@include breakPoint($breakPointMob){
			font-size: 24px;
		}

		@include breakPoint($breakPointTab1){
			font-size: 20px;
		}

		@include breakPoint($breakPointDesk1){
			font-size: 13px;
			display: inline-block;
			vertical-align: middle;
			margin-top: 12px;
		}

		&-item{
			font-weight: 500;
			margin: 25px 0;

			@include breakPoint($breakPointMob){
				margin: 50px 0;
			}

			@include breakPoint($breakPointDesk1){
				display: inline-block;
				vertical-align: bottom;
				margin: 0 0 0 20px;
			}

			@include breakPoint($breakPointDesk2){
				margin: 0 0 0 30px;
			}
		}
	}

	&__menu{

		@include breakPoint($breakPointDesk1){
			float: right;
		}

		&-item{
			margin: 20px 0;

			@include breakPoint($breakPointMob){
				margin: 30px 0;
			}

			@include breakPoint($breakPointDesk1){
				margin: 0;
				display: inline-block;
				vertical-align: middle;
			}

			.btn{
				@include breakPoint(0, $breakPointDesk1 - 1px){
					display: block;
				}

				&_border{

					@include breakPoint($breakPointDesk1){
						border-color: transparent;
						color: $colorText;
						padding-right: 0;

						&:hover{
							color: inherit;
							border-color: transparent;
						}
					}
				}
			}
		}
	}

	&__handler{
		width: 23px;
		height: 23px;
		position: absolute;
		top: 5px;
		right: 0;

		@include burgerButton('.is-menu-open', 23px, 3px, 10px);

		@include breakPoint($breakPointMob){
			width: 46px;
			height: 46px;
			top: 9px;
			@include burgerButton('.is-menu-open', 46px, 4px, 10px);
		}

		@include breakPoint($breakPointTab1){
			width: 58px;
			height: 58px;
			top: 3px;
			@include burgerButton('.is-menu-open', 58px, 5px, 10px);
		}

		@include breakPoint($breakPointDesk1){
			display: none;
		}
	}

	&__part{
		display: none;

		@include breakPoint(0, $breakPointDesk1 - 1px){
			border-top: 1px solid $colorBorder;
			text-align: center;
			display: table;
			width: 100%;
		}

		@include breakPoint($breakPointDesk1){
			white-space: nowrap;
			margin-left: 35px;
		}


		&-item{
			display: table-cell;
			width: 50%;
			font-size: 14px;
			font-weight: 500;
			height: 43px;
			padding-top: 5px;
			vertical-align: middle;

			@include breakPoint($breakPointMob){
				font-size: 24px;
				font-weight: 400;
				height: 88px;
				padding: 0;
			}

			@include breakPoint($breakPointTab1){
				height: 60px;
			}


			@include breakPoint($breakPointDesk1){
				display: inline-block;
				font-size: 13px;
				height: auto;
				vertical-align: middle;
				margin-right: 5px;
			}


			&.is-active{
				border-bottom: 2px solid $colorYellow;
				color: $colorYellow;

				@include breakPoint($breakPointMob){
					color: #fff;
					background: $colorYellow;
					border: 0;
				}

				@include breakPoint($breakPointDesk1){
					background: none;
					color: $colorYellow;
				}

				.ico-like{

					@include sprite-ico-like-mob-yellow;

					@include breakPoint($breakPointMob, $breakPointDesk1 - 1px){
						@include sprite-ico-like-tab-white;
					}

					@include breakPoint($breakPointDesk1){
						@include sprite-ico-like-mob-yellow;
					}
				}
			}

			.ico-like{
				display: inline-block;
				vertical-align: middle;
				@include sprite-ico-like-mob-deepgrey(true);
				margin-right: 7px;
				margin-top: -3px;

				@include breakPoint($breakPointMob, $breakPointDesk1 - 1px){
					margin-top: -5px;
					@include sprite-ico-like-tab-deepgrey;
				}

				@include breakPoint($breakPointDesk1){
					@include sprite-ico-like-mob-grey;
					margin-right: 4px;
				}

			}
		}

	}

	&__search{
		@include breakPoint($breakPointDesk1){
			display: none;
		}
	}


	&__user{
		display: inline-block;
		vertical-align: middle;
		float: right;

		&.is-open{

			@include breakPoint(0, $breakPointDesk1 - 1px){
				position: absolute;
				top: -8px;
				bottom: -8px;
				left: -15px;
				right: -15px;
				margin: 0;
				padding: 7px 15px;
				background: #fff;
				z-index: 1;

				.header__user-logo{
					position: static;
					display: inline-block;
					vertical-align: middle;

					&:after{
						right: 15px;
					}
				}

				.header__user-name{
					display: inline-block;
					vertical-align: middle;
				}

				.header__user-info{
					position: absolute;
					display: block;
					top: 100%;
					left: 0;
					right: 0;
					padding: 20px 15px;
					border-top: 1px solid $colorBorder;
					background: #fff;

					.btn{
						min-width: 200px;
					}
				}

			}

			@include breakPoint($breakPointMob, $breakPointDesk1 - 1px){
				top: -20px;
				bottom: -20px;
				left: -30px;
				right: -30px;
				margin: 0;
				padding: 19px 30px;



				.header__user-logo{
					&:after{
						right: 30px;
					}
				}

				.header__user-info{
					padding: 40px 30px;

					.btn{
						min-width: 400px;
					}
				}
			}

			@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
				padding: 25px 24px;
				left: -24px;
				right: -24px;

				.header__user-info{
					padding: 40px 24px;

					.btn{
						min-width: 300px;
					}
				}
			}

			@include breakPoint($breakPointDesk1){

				position: relative;

				.header__user-logo{
					&:before{
						content: '';
						display: block;
						border: 12px solid transparent;
						border-bottom-color: #fff;
						position: absolute;
						bottom: -12px;
						left: 50%;
						margin-left: -12px;
						z-index: 1;
					}
				}

				.header__user-info{
					position: absolute;
					display: block;
					top: 100%;
					right: 0;
					padding: 20px 15px;
					background: #fff;
					margin-top: 10px;
					width: 200px;
					@include box-shadow(0 0 5px rgba(0,0,0,.3));

					.btn{
						display: block;
					}
				}
			}

		}


		@include breakPoint($breakPointTab1){
			margin-top: 4px;
		}

		@include breakPoint($breakPointDesk1){
			margin: -8px 0;
		}

		&-logo{
			width: 35px;
			height: 35px;
			margin-right: 20px;
			position: relative;

			@include breakPoint($breakPointMob, $breakPointTab1 - 1px){
				margin-right: 35px;
				width: 66px;
				height: 66px;
			}

			@include breakPoint($breakPointTab1){
				margin-right: 35px;
				width: 54px;
				height: 54px;
			}

			@include breakPoint($breakPointDesk1){
				margin-right: 15px;
				width: 44px;
				height: 44px;
				display: inline-block;
				vertical-align: middle;
			}

			&:after{
				content: '';
				position: absolute;
				top: 50%;
				display: block;
				border: 5px solid transparent;
				border-top: 7px solid $colorGrey;
				right: -20px;
				margin-top: -2px;

				@include breakPoint($breakPointMob, $breakPointDesk1 - 1px){
					margin-top: -4px;
					border: 7px solid transparent;
					border-top: 9px solid $colorGrey;
					right: -30px;
				}

				@include breakPoint($breakPointTab1){
					margin-top: -2px;
				}

				@include breakPoint($breakPointDesk1){
					display: none;
				}
			}



			img{
				display: block;
				width: 100%;
				height: 100%;
				@include border-radius(50%);
			}
		}

		&-name{
			display: none;

			@include breakPoint($breakPointDesk1){
				display: inline-block;
				vertical-align: middle;
				white-space: nowrap;
				max-width: 250px;
				overflow: hidden;
				font-size: 13px;
				text-overflow: ellipsis;
			}
		}

		&-info{
			display: none;
		}

	}


}




.nav{

	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
	border-top: 1px solid $colorBorder;
	background: inherit;

	height: 43px;

	@include breakPoint($breakPointMob){
		height: 84px;
	}


	@include breakPoint($breakPointTab1){
		position: relative;
		bottom: auto;
		font-size: 22px;
		border-top: 0;
		border-bottom: 1px solid $colorBorder;
		height: 58px;
	}

	@include breakPoint($breakPointDesk1){
		font-size: 13px;
		height: 54px;
	}

	&__inner{
		background: inherit;

		height: 43px;

		.is-fixed &{
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			@include box-shadow(0 3px 3px rgba(0,0,0,.3));
		}

		@include breakPoint($breakPointMob){
			height: 84px;
		}

		@include breakPoint($breakPointTab1){
			height: 58px;
		}

		@include breakPoint($breakPointDesk1){
			height: 54px;
		}

	}

	&__scroll{
		background: #fff;
		width: auto;
		overflow: hidden;
		white-space: nowrap;
		overflow-x: auto;
		margin-bottom: -20px;
		padding-bottom: 20px;

		@include breakPoint($breakPointTab1){
			background: none;
			white-space: normal;
		}

		@include breakPoint($breakPointDesk1){
			overflow: visible;
			margin: 0;
			padding: 0;
			position: relative;
		}

	}

	&__title{
		display: none;

		@include breakPoint($breakPointDesk1){
			display: inline-block;
			vertical-align: middle;
			font-weight: 500;
			color: $colorBlack;
			padding-right: 16px;
			margin-right: 14px;
			border-right: 1px solid $colorBorder;
			height: 25px;
			line-height: 25px;
		}
	}

	&__menu{

		@include breakPoint($breakPointDesk1){
			display: inline-block;
			vertical-align: middle;
		}

		&-list{

		}

		&-item{
			display: inline-block;
			vertical-align: middle;
			padding: 12px 0;
			margin-right: 25px;
			position: relative;

			@include breakPoint($breakPointMob){
				padding: 28px 0;
			}

			@include breakPoint($breakPointTab1){
				padding: 16px 0;
			}

			@include breakPoint($breakPointDesk1){
				margin-right: 20px;
				padding: 20px 0;
			}

			&.is-active{
				color: $colorBlack!important;

				&:after{
					content: '';
					display: block;
					position: absolute;
					height: 2px;
					width: 100%;
					left: 0;
					bottom: 0;
					background: $colorYellow;
				}
			}

			&:hover{
				color: $colorYellow;
			}
		}
	}

	&__sort{
		display: none;

		@include breakPoint($breakPointDesk1){
			display: inline-block;
			vertical-align: middle;
			margin-left: 20px;
		}


		&-title{
			font-weight: 500;
			color: $colorBlack;
			display: inline-block;
			vertical-align: middle;
		}

		&-list{
			display: inline-block;
			vertical-align: middle;
			position: relative;

			&:after{
				content: '';
				display: inline-block;
				vertical-align: middle;
				border: 5px solid transparent;
				border-top-color: $colorGrey;
				position: absolute;
				right: -16px;
				top: 6px;
			}

			&.is-open{
				position: absolute;
				padding: 5px 15px;
				margin-top: -10px;
				margin-left: -13px;
				background: $colorBG;
				@include box-shadow(0 0 10px rgba(0,0,0,0.3));

				&:after{
					display: none;
				}

				.nav__sort-item{
					display: block;
					padding: 5px 0;

					&.is-active,
					&:hover{
						color: $colorYellow;
					}
				}
			}
		}

		&-item{
			display: none;

			&.is-active{
				display: block;
			}
		}
	}

	&__search{
		display: none;

		@include breakPoint($breakPointDesk1){
			display: inline-block;
			vertical-align: middle;
		}

	}
}

