
.footer {
	//position: relative;
	//margin-top: (- $footerHeight);
	//height: $footerHeight;

	background: $colorBlack;
	font-size: 14px;
	padding: 20px 0;

	@include breakPoint($breakPointMob){
		padding: 50px 0 40px;
	}


	@include breakPoint($breakPointMob){
		font-size: 24px;
	}

	@include breakPoint($breakPointTab1){
		font-size: 16px;
	}

	@include breakPoint($breakPointDesk1){
		font-size: 13px;
	}

	.inner{
		position: relative;
		margin: 0 15px;

		@include breakPoint(0, $breakPointDesk1 - 1px){
			width: auto;
		}

		@include breakPoint($breakPointMob){
			margin: 0 30px;
		}

		@include breakPoint($breakPointTab1){
			margin: 0 24px;
		}

		@include breakPoint($breakPointTab2){
		}

		@include breakPoint($breakPointDesk1){
			margin: 0 auto;
		}
	}

	&__menu{

		margin-bottom: 15px;

		@include breakPoint($breakPointMob){
			margin-bottom: 35px;
		}
	}

	&__logo{

		display: block;

		@include breakPoint($breakPointDesk1){
			display: inline-block;
			vertical-align: bottom;
		}


		&-img{
			@include sprite-footer-logo-mob(true);
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;

			@include breakPoint($breakPointMob){
				margin-right: 15px;
				@include sprite-footer-logo-tab;
			}
		}

		&-text{
			font-size: 18px;
			font-weight: 500;
			color: #fff;
			display: inline-block;
			vertical-align: middle;

			@include breakPoint($breakPointMob){
				font-size: 28px;
			}

			@include breakPoint($breakPointTab1){
			}
		}

	}

	&__nav{

		display: none;

		@include breakPoint($breakPointDesk1){
			display: inline-block;
			vertical-align: bottom;
			margin-right: 50px;
			margin-bottom: 4px;
		}

		&-item{
			display: inline-block;
			vertical-align: bottom;
			margin-left: 30px;

			a {
				&:hover,
				&.is-active{
					color: #fff;

					.ico-like{
						@include sprite-ico-like-mob-white;

						@include breakPoint($breakPointMob){
							@include sprite-ico-like-tab-white;
						}

						@include breakPoint($breakPointDesk1){
							@include sprite-ico-like-mob-white;
						}
					}
				}
			}

			.ico-like{
				display: inline-block;
				vertical-align: middle;
				@include sprite-ico-like-mob-grey(true);
				margin-right: 5px;
				margin-top: -3px;


				@include breakPoint($breakPointMob){
					margin-right: 15px;
					margin-top: -5px;
					@include sprite-ico-like-tab-grey;

				}

				@include breakPoint($breakPointDesk1){
					margin-right: 5px;
					margin-top: -3px;
					@include sprite-ico-like-mob-grey;

				}


			}
		}

	}



	&__content{

	}

	&__cos{
		position: absolute;
		top: -3px;
		right: 0;

		@include breakPoint($breakPointDesk1){
			position: static;
			float: left;
		}

		&-item{

			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;

			@include breakPoint($breakPointMob){
				margin-right: 50px;
			}

			@include breakPoint($breakPointDesk1){
				margin-right: 10px;
			}

			&_vk{
				@include sprite-ico-soc-vk-mob-grey(true);

				@include breakPoint($breakPointMob){
					@include sprite-ico-soc-vk-tab-grey;
				}

				@include breakPoint($breakPointDesk1){
					@include sprite-ico-soc-vk-desk-grey;
				}
			}
			&_ig{
				@include sprite-ico-soc-ig-mob-grey(true);

				@include breakPoint($breakPointMob){
					@include sprite-ico-soc-ig-tab-grey;
				}

				@include breakPoint($breakPointDesk1){
					@include sprite-ico-soc-ig-desk-grey;
				}
			}
		}
	}

	&__copy{

		@include breakPoint($breakPointDesk1){
			margin-left: 115px;
			padding-top: 10px;
		}

		&-text{

			@include breakPoint($breakPointDesk1){
				display: inline;
			}
		}
		&-link{
			color: $colorYellow;

			@include breakPoint($breakPointDesk1){
				display: inline;
			}

			a{
				color: inherit;
			}
		}
	}


	&__subscribe{
		display: none;


		@include breakPoint($breakPointDesk1){
			width: 220px;
			display: block;
			float: right;
			margin-left: 100px;


			& ~ {

				.footer__menu{
					margin-bottom: 20px;

					.footer__nav{

					}
				}

				.footer__content{
					.footer__copy{
						margin-left: 0;
						margin-bottom: 20px;
						padding-top: 0;
					}
					.footer__cos{
						float: none;
					}
				}
			}

		}

		@include breakPoint($breakPointDesk2){
			width: 365px;
		}

		@include breakPoint($breakPointDesk2){
			width: 295px;
		}

		&-text{
			color: #fff;
			margin-bottom: 10px;
			line-height: 1.5;

			a{
				color: $colorYellow;
			}
		}
		&-note{
			margin-bottom: 20px;
		}
		&-form{
			max-width: 250px;

			.form{
				&__field{}
				&__input{}

				input{
					background: transparent;
					@include border-radius(100px);
					border: 1px solid $colorText;
					color: #fff!important;
					font-size: 13px;
					line-height: 30px;
					height: 32px;
				}
			}
		}
	}
}
