$fontMain: 'Roboto', sans-serif;
$fontTitle: 'Lora', serif;
$fontSize: 16px;
$footerHeight: 100px;



$formHeight: 42px;
$formBorderRadius: 5px;



$colorYellow: #ffb600;
$colorYellowHover: #ffc840;
$colorBlue: #004786;
$colorBlueHover: #003869;
$colorBlack: #141414;
$colorText: #666666;
$colorGrey: #8a8a8a;
$colorRed: #cf2b00;
$colorBorder: #dbdbdb;
$colorBG: #ededed;
$colorViolet: #846EB0;



$sprite: '';




//320 - 290
//640 - 580
//768 - 720
//1024 - 940
//1366 - 1260
//1920 - 1582


$innerMob1: 290px;
$innerMob2: 580px;
$innerTab1: 720px;
$innerTab2: 940px;
$innerDesk1: 940px;
$innerDesk2: 1230px;
$innerDesk3: 1580px;

$breakPointMob: $innerMob2 + 60px;
$breakPointTab1: $innerTab1 + 60px;
$breakPointTab2: $innerTab2 + 40px;
$breakPointDesk1: $innerDesk1 + 60px;
$breakPointDesk2: $innerDesk2 + 60px;
$breakPointDesk3: $innerDesk3 + 60px;

$cardHeightMob1: 240px;
$cardHeightMob2: 395px;
$cardHeightTab1: 490px;
$cardHeightTab2: 470px;
$cardHeightDesk1: 390px;
$cardHeightDesk2: 400px;
$cardHeightDesk3: 450px;