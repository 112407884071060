
$fieldHeight: $formHeight;
$borderRadius: $formBorderRadius;
$inputPadding: 0 20px;


.placeholder{
	position: absolute;
	color: $colorBlack;
	top: 0;
	right: 100%;
	left: 0;
	text-align: right;
	line-height: $fieldHeight;
	cursor: default;
	pointer-events: none;

	white-space: nowrap;
	@include box-sizing();
	@include transition(all 0.3s ease);
	padding: $inputPadding;
}


input:focus ~,
textarea:focus ~,
.f-filled{

	.placeholder{
		top: -1.7em;
		font-size: 0.8em;
	}
}


.f-message{
	.form__message{
		display: block;
	}
}

.f-error{
	.form__message{
		color: $colorRed;
	}



	.form__input{
		input{

			&[type="email"],
			&[type="password"],
			&[type="tel"],
			&[type="text"]{
				border-color: $colorRed;
			}

			&[type="checkbox"]{
				& ~ {
					.form__label{
						&:before{
							border-color: $colorRed;
						}
					}
				}
			}

			&[type="radio"]{
				& ~ {
					.form__label{
						&:before{
							border-color: $colorRed;
						}
					}
				}
			}
		}
	}
}


.form{
	font-size: 0;

	&__field{
		position: relative;
		margin-bottom: 36px;
		font-size: 16px;

		@include breakPoint($breakPointMob){
			font-size: 24px;
		}

		@include breakPoint($breakPointTab1){
			font-size: 16px;
		}

		&:last-child{
			margin-bottom: 0;
		}

		&.f-error{

			input{
				&[type="email"],
				&[type="password"],
				&[type="tel"],
				&[type="text"]{
					border-color: $colorRed!important;
				}
			}
		}

	}

	&__message{
		display: none;
		top: 8px;
		position: relative;
		//padding: $inputPadding;
	}

	&__label{
		position: relative;
	}

	&__input{
		position: relative;

		textarea, input{

		}


		input{

			@include box-shadow(none);
			font-size: inherit;
			@include border-radius(0);
			border-top: 0;
			border-right: 0;
			border-left: 0;

			&[type="email"],
			&[type="password"],
			&[type="tel"],
			&[type="text"]{
				@include box-sizing();
				width: 100%;
				display: block;
				color: $colorBlack;
				line-height: ($fieldHeight - 2px);
				height: $fieldHeight;
				border-bottom: 1px solid $colorBorder;
				background: #fff;
				@include transition('border 0.3s ease');
				padding: $inputPadding;

				&:focus{
					border-color: $colorYellow;
					color: $colorBlack;
				}

				&:hover{
					border-color: $colorYellow;
				}
			}


			&[type="submit"]{
				@include box-sizing();
				cursor: pointer;
				width: 100%;
				display: block;
			}

			&[type="checkbox"]{
				opacity: 0;
				position: absolute;

				&:checked{
					& ~ {
						.form__label{
							&:before{
							}

							&:after{
								@include sprite-ico-checkbox-mob-yellow(true);
							}
						}
					}
				}

				& ~ {
					.form__label{
						padding: 7px 0 7px 40px;

						a {
							color: $colorYellow;
							text-decoration: underline;
						}


						@include breakPoint($breakPointMob){
							padding: 15px 0 15px 75px;
						}

						@include breakPoint($breakPointDesk1){
							padding: 7px 0 7px 35px;
						}

						&:after{
							content: '';
							width: 22px;
							height: 22px;
							display: block;
							position: absolute;
							top: 5px;
							left: 1px;

							@include breakPoint($breakPointMob){
								top: 15px;
								left: 11px;
							}

							@include breakPoint($breakPointTab1){
								top: 4px;
								left: -1px;
							}
						}

						&:before{
							content: '';
							width: 22px;
							height: 22px;
							display: block;
							position: absolute;
							top: 4px;
							left: 0;
							@include border-radius($borderRadius);
							background: #fff;
							border: 1px solid $colorBorder;

							@include breakPoint($breakPointMob){
								width: 42px;
								height: 42px;
							}

							@include breakPoint($breakPointTab1){
								width: 34px;
								height: 34px;
							}

							@include breakPoint($breakPointDesk1){
								width: 18px;
								height: 18px;
							}

						}


						&:hover{
							&:before{
								border-color: $colorYellow;
							}
						}
					}
				}
			}

			&[type="radio"]{
				opacity: 0;
				position: absolute;

				&:checked{
					& ~ {
						.form__label{

							&:before{
								background-color: green;
							}
						}
					}
				}

				& ~ {
					.form__label{
						padding: 7px 0 7px 42px;

						&:before{
							content: '';
							width: 28px;
							height: 28px;
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							@include border-radius(100px);
							background: #fff;
							border: 1px solid $colorBorder;
						}
					}
				}
			}
		}

	}
}


