@import "../../../node_modules/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar";

$scrollBarWrapDim: 6px;

$scrollBarLineDim: 2px;
$scrollBarLineColor: transparent;

$scrollBarHandleDim: 6px;
$scrollBarHandleColor: $colorYellow;
$scrollBarHandleColorDrug: $colorYellow;

.mCustomScrollbar,
.mCustomScrollbar.mCS_touch_action,
.mCustomScrollbar.mCS_touch_action .mCS-aviva.mCSB_scrollTools {
	-ms-touch-action: auto;
	touch-action: auto;
}

.mCustomScrollBox {
	-ms-touch-action: pan-x;
	touch-action: pan-x;
}

.mCustomScrollBox.mCSB_vertical_horizontal {
	-ms-touch-action: auto;
	touch-action: auto;
}

.mCustomScrollBox.mCSB_horizontal {
	-ms-touch-action: pan-y;
	touch-action: pan-y;
}


.mCSB_horizontal.mCSB_inside > .mCSB_container,
.mCSB_inside > .mCSB_container{
	margin: 0;
}

.mCSB_scrollTools{
	width: $scrollBarWrapDim;
	&.mCSB_scrollTools_horizontal{
		height: $scrollBarWrapDim;
	}
}


.mCSB_scrollTools {
	.mCSB_draggerRail{
		width: $scrollBarLineDim;
		margin: 0 (($scrollBarWrapDim - $scrollBarLineDim) / 2);
		background: $scrollBarLineColor;
	}

	&.mCSB_scrollTools_horizontal{
		.mCSB_draggerRail{
			height: $scrollBarLineDim;
			margin: (($scrollBarWrapDim - $scrollBarLineDim) / 2) 0;
		}
	}
}


.mCSB_scrollTools {
	.mCSB_dragger {
		.mCSB_dragger_bar{
			width: $scrollBarHandleDim;
			margin: 0 (($scrollBarWrapDim - $scrollBarHandleDim) / 2);
			background: $scrollBarHandleColor;
			opacity: 1;
		}

		&.mCSB_dragger_onDrag,
		&:active,
		&:hover{
			.mCSB_dragger_bar{
				opacity: 1;
				background: $scrollBarHandleColorDrug;
			}
		}
	}

	&.mCSB_scrollTools_horizontal{
		.mCSB_dragger {
			.mCSB_dragger_bar{
				height: $scrollBarHandleDim;
				margin: (($scrollBarWrapDim - $scrollBarHandleDim) / 2) 0;
			}
		}
	}
}


.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover,
.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active,
.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools{ opacity: 0; filter: "alpha(opacity=0)"; -ms-filter: "alpha(opacity=0)"; }

