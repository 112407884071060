$popupZIndex: 100;
$popupOverlayColor: rgba(0, 0, 0, 0.8);



.body-popup{
	overflow: hidden;
}


.popup {
	font-family: $fontMain;
	font-size: 16px;
	color: $colorText;
	width: 100%;
	height: 100%;
	line-height: 1.2;
	display: none;

	@if ( $breakPointMob == 0 ){
		min-width: 640px;
	}

	@include breakPoint($breakPointMob){
		font-size: 24px;
	}

	@include breakPoint($breakPointTab1){
		font-size: 16px;
	}


	[data-tab-target]{
		display: none;
		&.is-active{
			display: block;
		}
	}
	b{
		font-weight: 500;
	}

	.btn{
		font-size: 16px;
		display: inline-block;
		cursor: pointer;
		font-family: $fontMain;

		@include box-sizing();
		@include border-radius(100px);

		background-color: $colorYellow;
		border: 1px solid $colorYellow;
		text-align: center;
		color: #fff;
		padding: 11px 15px 13px;
		font-weight: 400;
		height: auto;
		line-height: 1.2;

		@include breakPoint($breakPointMob){
			font-size: 24px;
			padding: 19px 30px 21px;
		}

		@include breakPoint($breakPointTab1){
			font-size: 20px;
			padding: 14px 20px 16px;
		}

		@include breakPoint($breakPointTab1){
			font-size: 15px;
			padding: 12px 35px 13px;
		}

		&:hover{
			background-color: $colorYellowHover;
			border: 1px solid $colorYellowHover;
		}

		&_grey{
			color: #fff;
			background: $colorGrey;
			border-color: $colorGrey;

			&:hover{
				background-color:  #9f9f9f;
				border-color: #9f9f9f;
			}
		}

		&_yellow{
			color: #fff;
			background: $colorYellow;
		}

		&_border{
			background-color: transparent;
			color: $colorYellow;

			&:hover{
				background-color: transparent;
				color: $colorYellowHover;
			}
		}

		&_clean{
			background: transparent;
			border-color: transparent;
			color: $colorGrey;

			&:hover{
				background: transparent;
				border-color: transparent;
				color: $colorBlack;
			}
		}

		&_violet{
			color: #fff;
			background: $colorViolet;
			border-color: $colorViolet;

			&:hover{
				background: #7661b0;
				border-color: #7661b0;
			}
		}

		&_disable{
			color: #fff;
			background: #ccc;
			border-color: #ccc;
			cursor: no-drop;
		}
	}

	&.is-open {
		display: block !important;
	}

	&__overlay {
		display: block;
		height: 100%;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: $popupZIndex;
		background-color: $popupOverlayColor;

		@include breakPoint($breakPointTab1){
			//background: url("../img/bg.jpg") no-repeat 50% 50%;
			//background-size: cover;
			background: #000;
		}


		&:before{
			content: '';
			background-color: $popupOverlayColor;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

	&__inner {
		height: 100%;
		left: 0;
		overflow: auto;
		position: fixed;
		text-align: center;
		top: 0;
		width: 100%;
		z-index: $popupZIndex;
		display: block;
		background: transparent;
		overflow-y: scroll;
		font-size: 0;

		@if ( $breakPointMob == 0 ){
			min-width: 640px;
		}

		&:before {
			content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}

	}

	&__layout {
		background-color: white;
		box-sizing: border-box;
		display: inline-block;
		margin: 0 auto;
		padding: 0;
		position: relative;
		vertical-align: middle;
		width: 100%;
		max-width: 600px;
		min-height: 100px;
		text-align: left;
		font-size: 16px;
	}

	&__close {
		width: 25px;
		height: 25px;
		position: absolute;
		cursor: pointer;
		top: 17px;
		right: 19px;

		@include breakPoint($breakPointMob){
			width: 50px;
			height: 50px;
			top: 14px;
		}

		@include breakPoint($breakPointTab1){
			width: 30px;
			height: 30px;
			background: #fff;
			@include border-radius(50%);
			top: -30px;
			right: -30px;
		}

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			background: $colorBlack;
			height: 4px;
			width: 30px;
			margin-top: -2px;
			margin-left: -15px;
			border-radius: 10px;

			@include breakPoint($breakPointMob){
				height: 6px;
				width: 60px;
				margin-top: -3px;
				margin-left: -30px;
			}

			@include breakPoint($breakPointTab1){
				width: 50px;
				margin-left: -25px;
			}

			@include breakPoint($breakPointTab1){
				height: 3px;
				width: 20px;
				margin-top: -2px;
				margin-left: -10px;
			}


		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}

		&:hover {

		}
	}

	&_auth{
		.popup{

			&__layout{
				min-height: 100vh;
				max-width: 100%;

				@include breakPoint($breakPointTab1){
					min-height: 0;
					max-width: 490px;
					margin: 40px 0 20px;

					@include border-radius(15px);
				}

				@include breakPoint($breakPointDesk3){
					max-width: 616px;
				}
			}

			&__header{
				padding: 12px 15px;

				@include breakPoint($breakPointTab1){
					display: none;
				}

				&-logo{
					display: inline-block;
					vertical-align: middle;
					@include sprite-header-logo-mob(true);
					margin-right: 5px;

					@include breakPoint($breakPointMob){
						@include sprite-header-logo-tab;
						margin-right: 15px;
					}

					@include breakPoint($breakPointTab1){
						@include sprite-header-logo-desk_round;
						margin-right: 15px;
					}
				}

				&-text{
					display: inline-block;
					vertical-align: middle;
					font-size: 15px;
					color: $colorBlack;
					font-weight: 500;

					@include breakPoint($breakPointMob){
						font-size: 30px;
					}

					@include breakPoint($breakPointTab1){
						font-size: 24px;
					}
				}
			}

			&__tab{
				font-size: 0;
				border-top: 1px solid $colorBorder;
				border-bottom: 1px solid $colorBorder;

				@include breakPoint($breakPointTab1){
					border-top: none;
				}

				&-item{
					text-align: center;
					height: 40px;
					line-height: 40px;
					font-size: 14px;
					width: 50%;
					display: inline-block;
					vertical-align: top;
					cursor: pointer;

					@include breakPoint($breakPointMob){
						height: 86px;
						line-height: 86px;
						font-size: 30px;
					}

					@include breakPoint($breakPointTab1){
						height: 56px;
						line-height: 56px;
						font-size: 22px;
					}

					@include breakPoint($breakPointTab1){
						font-size: 16px;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
					}
					@include breakPoint($breakPointDesk3){
						font-size: 24px;
						padding-bottom: 10px;
					}

					&.is-active{

						@include breakPoint(0, $breakPointTab1 - 1px){
							color: #fff;
							background: $colorYellow;
						}

						@include breakPoint($breakPointTab1){
							border-bottom: 2px solid $colorYellow;
							margin-bottom: -1px;
							color: $colorYellow;
						}
					}
				}
			}


		}



		.form{
			width: 245px;
			margin: 0 auto;
			text-align: left;

			@include breakPoint($breakPointMob){
				width: 490px;
			}

			@include breakPoint($breakPointTab1){
				width: 460px;
			}

			@include breakPoint($breakPointTab1){
				width: 235px;
			}

			&_pass{
				display: none;
			}

			.placeholder{
				padding: 0 8px;
				line-height: 32px;

				@include breakPoint($breakPointMob){
					line-height: 60px;
				}

				@include breakPoint($breakPointTab1){
					line-height: 30px;
				}
			}


			.btn{

				@include breakPoint($breakPointTab1, $breakPointDesk2 - 1px){
					padding-top: 5px;
					padding-bottom: 5px;
				}
			}


			&__field{
				position: relative;
				margin-bottom: 20px;
				font-size: 14px;

				@include breakPoint($breakPointMob){
					margin-bottom: 30px;
					font-size: 24px;
				}

				@include breakPoint($breakPointTab1){
					font-size: 20px;
				}

				@include breakPoint($breakPointTab1){
					font-size: 14px;
					margin-bottom: 20px;
				}

				&:last-child{
					margin-bottom: 0;
				}

				&_submit{
					margin-top: 30px;

					@include breakPoint($breakPointMob){
						margin-top: 50px;
					}
					@include breakPoint($breakPointTab1){
						margin-top: 20px;
					}

					@include breakPoint($breakPointDesk2){
						margin-top: 30px;
					}
				}

				&.f-filled.f-error{
					.form__message{
						& ~ .form__input{
							.placeholder{
								opacity: 0;
							}
						}
					}
				}

				&.f-error{
					.form__message{
						& ~ .form__input{

							input:focus{
								& ~ .placeholder{
									opacity: 0;
								}
							}
						}
					}
				}

			}

			&__input{
				position: relative;


				input{

					&[type="email"],
					&[type="password"],
					&[type="tel"],
					&[type="text"]{
						@include box-sizing();
						line-height: 31px;
						height: 32px;
						padding: 0 8px;
						background: transparent;

						@include breakPoint($breakPointMob){
							height: 60px;
							line-height: 59px;
						}

						@include breakPoint($breakPointTab1){
							line-height: 31px;
							height: 32px;
						}
					}
				}
			}

			&__message{
				position: absolute;
				padding: 0 8px;
				top: -1em;
				font-size: 0.8em;

				@include breakPoint($breakPointTab1){
					top: -0.5em;
				}

				@include breakPoint($breakPointTab1){
					top: -0.9em;
				}


			}

			&__text{
				text-align: center;
				font-size: inherit;

				@include breakPoint($breakPointTab1){
					font-size: 13px;
				}

				a{
					color: $colorYellow;
				}
			}
		}

	}

	&__auth{
		text-align: center;
		padding: 50px 0;
		font-weight: 300;

		@include breakPoint($breakPointMob){
			padding: 100px 0;
		}

		@include breakPoint($breakPointTab1){
			padding: 30px 0;
		}

		&.is-registered{
			.form_reg,
			.popup__auth-soc{
				display: none;
			}

			.form_pass{
				display: block;
			}
		}

		&-title{
			font-weight: 500;
			font-size: 14px;
			color: $colorBlack;
			margin-bottom: 35px;

			@include breakPoint($breakPointMob){
				font-size: 30px;
				margin-bottom: 70px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 24px;
			}


			@include breakPoint($breakPointTab1){
				margin-bottom: 40px;
			}
		}

		&-text{
			font-size: 12px;
			color: $colorBlack;
			margin-bottom: 35px;

			@include breakPoint($breakPointMob){
				font-size: 26px;
				margin-bottom: 70px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 20px;
			}


			@include breakPoint($breakPointTab1){
				margin-bottom: 40px;
			}
		}


		&-soc{
			font-size: 12px;

			@include breakPoint($breakPointMob){
				font-size: 20px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 16px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 13px;
			}

			&-text{
				.or{
					display: inline-block;
					position: relative;

					&:before,
					&:after{
						content: '';
						background: $colorBorder;
						display: inline-block;
						height: 1px;
						width: 22px;
						vertical-align: middle;
						margin: 0 4px;

						@include breakPoint($breakPointMob){
							width: 46px;
							margin: 0 12px;
						}
					}
				}
			}

			&-list{
				margin: 30px 0;

				@include breakPoint($breakPointMob){
					margin: 50px 0;
				}

				@include breakPoint($breakPointTab1){
					margin: 30px 0;
				}
			}

			&-item{
				@include spriteImage;
				display: inline-block;
				vertical-align: middle;
				margin: 0 10px;

				@include breakPoint($breakPointMob){
					margin: 0 23px;
				}

				@include breakPoint($breakPointTab1){
					margin: 0 19px;
				}

				@include breakPoint($breakPointTab1){
					margin: 0 10px;
				}

				&_vk{
					@include sprite-ico-reg-soc-vk-mob-color;

					@include breakPoint($breakPointMob){
						@include sprite-ico-reg-soc-vk-mobx-color;
					}

					@include breakPoint($breakPointTab1){
						@include sprite-ico-reg-soc-vk-tab-color;
					}

					@include breakPoint($breakPointTab1){
						@include sprite-ico-reg-soc-vk-desk-grey;
					}

					&:hover{
						@include breakPoint($breakPointTab1){
							@include sprite-ico-reg-soc-vk-desk-color;
						}
					}
				}
				&_fb{
					@include sprite-ico-reg-soc-fb-mob-color;

					@include breakPoint($breakPointMob){
						@include sprite-ico-reg-soc-fb-mobx-color;
					}

					@include breakPoint($breakPointTab1){
						@include sprite-ico-reg-soc-fb-tab-color;
					}

					@include breakPoint($breakPointTab1){
						@include sprite-ico-reg-soc-fb-desk-grey;
					}

					&:hover{
						@include breakPoint($breakPointTab1){
							@include sprite-ico-reg-soc-fb-desk-color;
						}
					}
				}
				&_ok{
					@include sprite-ico-reg-soc-ok-mob-color;

					@include breakPoint($breakPointMob){
						@include sprite-ico-reg-soc-ok-mobx-color;
					}

					@include breakPoint($breakPointTab1){
						@include sprite-ico-reg-soc-ok-tab-color;
					}

					@include breakPoint($breakPointTab1){
						@include sprite-ico-reg-soc-ok-desk-grey;
					}

					&:hover{
						@include breakPoint($breakPointTab1){
							@include sprite-ico-reg-soc-ok-desk-color;
						}
					}
				}
				&_gp{
					@include sprite-ico-reg-soc-gp-mob-color;

					@include breakPoint($breakPointMob){
						@include sprite-ico-reg-soc-gp-mobx-color;
					}

					@include breakPoint($breakPointTab1){
						@include sprite-ico-reg-soc-gp-tab-color;
					}

					@include breakPoint($breakPointTab1){
						@include sprite-ico-reg-soc-gp-desk-grey;
					}

					&:hover{

						@include breakPoint($breakPointTab1){
							@include sprite-ico-reg-soc-gp-desk-color;
						}
					}
				}
			}
		}


		&-form{
		}
	}


	&__info{
		text-align: center;
		padding: 50px 0;
		font-weight: 300;
		width: 245px;
		margin: 0 auto;


		@include breakPoint($breakPointMob){
			width: 490px;
			padding: 100px 0;
		}

		@include breakPoint($breakPointTab1){
			width: 460px;
		}

		@include breakPoint($breakPointTab1){
			padding: 30px;
			width: auto;
		}


		&-title{
			font-weight: 500;
			font-size: 14px;
			color: $colorBlack;
			margin-bottom: 35px;

			@include breakPoint($breakPointMob){
				font-size: 30px;
				margin-bottom: 70px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 24px;
			}


			@include breakPoint($breakPointTab1){
				margin-bottom: 40px;
			}
		}

		&-text{
			font-size: 12px;
			color: $colorBlack;
			margin-bottom: 35px;

			p{
				margin: 15px 0;
			}

			b{
				color: $colorYellow;
			}

			@include breakPoint($breakPointMob){
				font-size: 18px;
				margin-bottom: 70px;
			}

			@include breakPoint($breakPointTab1){
			}


			@include breakPoint($breakPointTab1){
				margin-bottom: 40px;
				font-size: 14px;
			}

			@include breakPoint($breakPointDesk3){
			}
		}


		&-btn{

			.btn{

				width: 100%;
				margin-bottom: 20px;

				@include breakPoint($breakPointTab1){
					width: auto;
					margin: 0 10px;
				}
			}
		}
	}


	&__conditer{
		color: #000;

		&-subtitle{
			font-size: 12px;
			color: #666;
			text-align: center;
			margin-bottom: 17px;

			@include breakPoint($breakPointMob){
				font-size: 18px;
				margin-bottom: 25px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 14px;
			}
		}
		&-title{
			font-size: 42px;
			text-align: center;
			margin-bottom: 30px;

			@include breakPoint($breakPointMob){
				font-size: 62px;
				margin-bottom: 44px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 48px;
			}
		}
		&-courses{
			margin-bottom: 67px;
			font-size: 0;

			@include breakPoint($breakPointMob){
				margin-bottom: 100px;
			}

			@include breakPoint($breakPointDesk1){
				margin: 0 -14px 120px;
			}

		}

		&-item{
			box-sizing: border-box;
			margin-bottom: 19px;

			@include breakPoint($breakPointMob){
				margin-bottom: 28px;
			}

			@include breakPoint($breakPointDesk1){
				display: inline-block;
				padding: 0 14px;
				width: (100% / 3);
				margin: 0;
			}


			&.is-active{

				.popup__conditer-item-inner {
					&:before{
						content: '';
						display: block;
						position: absolute;
						top: -2px;
						left: -2px;
						right: -2px;
						bottom: -2px;
						border: 4px solid $colorYellow;
						border-radius: 20px;
						pointer-events: none;

						@include breakPoint($breakPointMob){
							top: -3px;
							left: -3px;
							right: -3px;
							bottom: -3px;
							border-width: 6px;
							border-radius: 14px;
						}
					}
				}



				.popup__conditer-item-status{


					.btn{
						background: transparent;
						color: #000;

						.off{
							display: none;
						}

						.on{
							display: inline-block;
						}
					}
				}
			}
			&.is-disable{

				.popup__conditer-item-status{


					.btn{
						color: #fff;
						background: #ccc;
						border-color: #ccc;
						cursor: no-drop;
					}
				}

			}

			&-input{
				position: absolute;
				opacity: 0;
				pointer-events: none;
			}

			&-inner{
				border-radius: 10px;
				display: block;
				background: #fff;
				padding: 40px 27px;
				box-shadow: 0 1px 4px rgba(#666, 0.2);
				position: relative;

				@include breakPoint($breakPointMob){
					border-radius: 14px;
					padding: 60px 40px;
				}

				@include breakPoint($breakPointDesk1){
					padding: 30px 40px 40px;
				}


			}

			&-title{
				font-size: 20px;

				border-bottom: 1px solid #ccc;
				padding-bottom: 21px;
				margin-bottom: 21px;

				@include breakPoint($breakPointMob){
					font-size: 31px;
					padding-bottom: 32px;
					margin-bottom: 30px;
				}

				@include breakPoint($breakPointDesk1){
					font-size: 24px;
					padding-bottom: 10px;
					margin-bottom: 30px;
				}
			}

			&-price{
				margin-bottom: 17px;

				@include breakPoint($breakPointMob){
					margin-bottom: 20px;
				}

				@include breakPoint($breakPointDesk1){
					margin-bottom: 10px;
				}

				.old-price{
					font-size: 22px;

					@include breakPoint($breakPointMob){
						font-size: 33px;
					}

					@include breakPoint($breakPointDesk1){
						font-size: 26px;
					}

					span{
						display: inline-block;
						padding: 0 3px;
						position: relative;

						@include breakPoint($breakPointMob){
							padding: 0 5px;
						}

						&:after{
							content: '';
							display: block;
							position: absolute;
							top: 50%;
							height: 1px;
							left: 0;
							right: 0;
							background: $colorYellow;

							@include breakPoint($breakPointMob){
								height: 2px;
							}
						}
					}
				}
				.new-price{
					font-size: 42px;
					color: $colorYellow;

					@include breakPoint($breakPointMob){
						font-size: 62px;
					}

					@include breakPoint($breakPointDesk1){
						font-size: 48px;
					}
				}
			}

			&-info{
				color: #858585;
				font-size: 12px;
				margin-bottom: 24px;

				@include breakPoint($breakPointMob){
					font-size: 18px;
					margin-bottom: 35px;
				}

				@include breakPoint($breakPointDesk1){
					font-size: 14px;
				}
			}

			&-status{

				.btn{
					width: 100%;

					.on{
						display: none;
					}
				}


			}
		}


		&-order{
			font-size: 0;
		}

		&-form{
			background: #fff;
			border-radius: 10px;
			box-shadow: 0 1px 4px rgba(#666, 0.2);
			padding: 37px 30px;
			box-sizing: border-box;
			margin-bottom: 20px;

			@include breakPoint($breakPointMob){
				border-radius: 14px;
				padding: 55px 45px;
				margin-bottom: 30px;
			}

			@include breakPoint($breakPointDesk1){
				display: inline-block;
				width: calc(100% - 484px);
				padding: 55px 7.5%;
				vertical-align: top;
				margin-bottom: 0;
			}
		}



		&-result{
			padding: 24px;

			@include breakPoint($breakPointMob){
				padding: 35px;

			}

			@include breakPoint($breakPointDesk1){
				padding: 35px 0;
				width: 354px;
				margin-left: 130px;
				display: inline-block;
				vertical-align: top;
			}

			.btn{
				width: 100%;
			}

			.table{
				display: table;
				width: 100%;
				font-size: 12px;
				color: #666666;

				@include breakPoint($breakPointMob){
					font-size: 18px;
				}

				@include breakPoint($breakPointDesk1){
					font-size: 14px;
				}

				&-row{
					display: table-row;
				}

				&-cell{
					display: table-cell;
					padding-bottom: 17px;

					@include breakPoint($breakPointMob){
						padding-bottom: 25px;
					}

					@include breakPoint($breakPointDesk1){
						padding-bottom: 13px;
					}

					&_val{
						font-size: 16px;
						color: #000;
						text-align: right;

						@include breakPoint($breakPointMob){
							font-size: 24px;
						}

						@include breakPoint($breakPointDesk1){
							font-size: 18px;
						}
					}

					&_big{
						font-size: 22px;
						color: $colorYellow;

						@include breakPoint($breakPointMob){
							font-size: 33px;
						}

						@include breakPoint($breakPointDesk1){
							font-size: 26px;
						}
					}
				}
			}
		}

		&-table{

			border-bottom: 1px solid #ccc;
			margin-bottom: 35px;

			@include breakPoint($breakPointMob){
				margin-bottom: 50px;

			}

			@include breakPoint($breakPointDesk1){
				margin-bottom: 30px;
			}
		}

		&-submit{
			margin-bottom: 27px;

			@include breakPoint($breakPointMob){
				margin-bottom: 40px;
			}

			@include breakPoint($breakPointDesk1){
				margin-bottom: 50px;
			}

			&:last-child{
				margin-bottom: 0;
			}
		}


	}


}



.popup_conditer{

	.form{
		width: auto;
	}

	.popup{

		&__overlay{
			background: #000;
		}

		&__close{

			@include breakPoint($breakPointTab1){
				top: 30px;
				right: 30px;
				background: transparent;
			}
		}


		&__layout{
			min-height: 100vh;
			max-width: 100%;
			background: #F9F6F5;

			@include breakPoint($breakPointTab1){
				min-height: 0;
				max-width: 640px;
				margin: 40px 0 20px;

				@include border-radius(15px);
			}

			@include breakPoint($breakPointDesk1){
				max-width: 1582px;
				width: calc(100% - 40px);
			}
		}

		&__content{
			padding: 90px 30px;

			@include breakPoint($breakPointDesk1){
				padding: 90px 30px;
				max-width: 1262px;
				margin: 0 auto;
			}
		}

		&__header{
			padding: 12px 15px;

			@include breakPoint($breakPointTab1){
				display: none;
			}

			&-logo{
				display: inline-block;
				vertical-align: middle;
				@include sprite-header-logo-mob(true);
				margin-right: 5px;

				@include breakPoint($breakPointMob){
					@include sprite-header-logo-tab;
					margin-right: 15px;
				}

				@include breakPoint($breakPointTab1){
					@include sprite-header-logo-desk_round;
					margin-right: 15px;
				}
			}

			&-text{
				display: inline-block;
				vertical-align: middle;
				font-size: 15px;
				color: $colorBlack;
				font-weight: 500;

				@include breakPoint($breakPointMob){
					font-size: 30px;
				}

				@include breakPoint($breakPointTab1){
					font-size: 24px;
				}
			}
		}

	}
}

.hint{

	position: relative;

	font-size: 12px;
	pointer-events: all;

	@include breakPoint($breakPointMob){
		font-size: 18px;
	}

	@include breakPoint($breakPointDesk1){
		font-size: 14px;
	}

	&_top{
		.hint__content{
			top: auto;
			bottom: -5px;
		}
	}

	&.is-hover{
		z-index: 3;

		.hint__content{
			opacity: 1;
			pointer-events: all;
		}
	}

	&:after{
		content: '?';
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		color: #666;
		display: inline-block;
		width: 16px;
		height: 16px;
		border: 1px solid #ccc;
		border-radius: 50%;
		position: relative;
		z-index: 2;

		@include breakPoint($breakPointMob){
			font-size: 18px;
			line-height: 24px;
			width: 24px;
			height: 24px;
		}

		@include breakPoint($breakPointDesk1){
			font-size: 13px;
			width: 18px;
			height: 18px;
			line-height: 18px;
		}
	}


	&__content{
		position: absolute;
		background: #fff;
		border-radius: 10px;
		box-shadow: 0 1px 4px rgba(#666, 0.2);
		padding: 17px 23px 23px;
		box-sizing: border-box;
		text-align: left;
		top: -5px;
		left: -5px;
		width: 300px;
		z-index: 1;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s ease;
		white-space: normal;
		line-height: 1.5;

		@include breakPoint($breakPointMob){
			border-radius: 14px;
			padding: 25px 30px 30px;
			width: 400px;
		}


		@include breakPoint($breakPointDesk1){
			width: 300px;
		}
	}
}