

html {
	height: 100%;
	background: $colorBlack;
}

body {
	font-family: $fontMain;
	font-size: 16px;
	color: $colorText;
	width: 100%;
	height: 100%;
	line-height: 1.2;

	@include breakPoint($breakPointMob){
		font-size: 24px;
	}

	@include breakPoint($breakPointTab1){
		font-size: 16px;
	}
}



[data-tab-target]{
	display: none;
	&.is-active{
		display: block;
	}
}

b{
	font-weight: 500;
}

.btn{
	font-size: 16px;
	display: inline-block;
	cursor: pointer;
	font-family: $fontMain;

	@include box-sizing();
	@include border-radius(100px);

	background-color: $colorYellow;
	border: 1px solid $colorYellow;
	text-align: center;
	color: #fff;
	padding: 11px 15px 13px;
	font-weight: 400;

	@include breakPoint($breakPointMob){
		font-size: 24px;
		padding: 19px 30px 21px;
	}

	@include breakPoint($breakPointTab1){
		font-size: 20px;
		padding: 14px 20px 16px;
	}

	@include breakPoint($breakPointDesk1){
		font-size: 15px;
		padding: 12px 35px 13px;
	}

	&:hover{
		background-color: $colorYellowHover;
		border: 1px solid $colorYellowHover;
	}

	&_border{
		background-color: transparent;
		color: $colorYellow;

		&:hover{
			background-color: transparent;
			color: $colorYellowHover;
		}
	}

	&_clean{
		background: transparent;
		border-color: transparent;
		color: $colorGrey;

		&:hover{
			background: transparent;
			border-color: transparent;
			color: $colorBlack;
		}
	}

	&_violet{
		color: #fff;
		background: $colorViolet;
		border-color: $colorViolet;
	}

	&_disable{
		color: #fff;
		background: #ccc;
		border-color: #ccc;
		cursor: no-drop;
	}
}

.wrapper {
	//min-height: 100%;
	//height: auto !important;
	//height: 100%;
	background: $colorBG;




	&_inside {

		& ~ {

			.footer{

				@include breakPoint(0, $breakPointMob - 1px){
					padding-bottom: 60px;
				}


				@include breakPoint($breakPointMob, $breakPointTab1 - 1px){
					padding-bottom: 120px;
				}
			}
		}

	}
}


.website {
	//padding-bottom: $footerHeight;
	@include clearfix;
}


.inner{
	margin: 0 auto;

	width: $innerMob1;


	@include breakPoint($breakPointMob){
		width: $innerMob2;
	}

	@include breakPoint($breakPointTab1){
		width: $innerTab1;
	}

	@include breakPoint($breakPointTab2){
		width: $innerTab2;
	}

	@include breakPoint($breakPointDesk1){
		width: $innerDesk1;
	}

	@include breakPoint($breakPointDesk2){
		width: $innerDesk2;
	}

	@include breakPoint($breakPointDesk3){
		width: $innerDesk3;
	}

}

section{
	margin: 20px 0;
}


.card{

	&_preview{
		overflow: hidden;
	}

	&__found{
		margin-bottom: 1em;
		span{
			color: $colorYellow;
		}
	}

	&__list{
		font-size: 0;
		margin: -10px;

		.card_preview &{
			max-height: $cardHeightMob1 * 2;

			@include breakPoint($breakPointMob){
				max-height: $cardHeightMob2 * 2;
			}

			@include breakPoint($breakPointTab1){
				max-height: $cardHeightTab1;
			}

			@include breakPoint($breakPointTab2){
				max-height: $cardHeightTab2;
			}

			@include breakPoint($breakPointDesk1){
				max-height: $cardHeightDesk1;
			}

			@include breakPoint($breakPointDesk2){
				max-height: $cardHeightDesk2;
			}

			@include breakPoint($breakPointDesk3){
				max-height: $cardHeightDesk3;
			}
		}


	}

	&__control{

		&-btn{
			position: absolute;
			top: 50%;
			width: 56px;
			height: 56px;
			z-index: 1;
			overflow: hidden;
			cursor: pointer;
			margin-top: -28px;

			@include breakPoint($breakPointMob){
				width: 112px;
				height: 112px;
				margin-top: -56px;
			}

			@include breakPoint($breakPointTab1){
				width: 90px;
				height: 90px;
				margin-top: -45px;
			}

			&:before{
				content: '';
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				@include border-radius(50%);
				background: rgba(#000, 0.5);
				overflow: hidden;
				@include transition(all 0.2s ease);
			}

			&:after{
				content: '';
				position: absolute;
				top: 21px;
				@include transition(all 0.2s ease);

				@include breakPoint($breakPointMob){
					top: 41px;
				}

				@include breakPoint($breakPointTab1){
					top: 33px;
				}
			}

			&:hover{
			}

			&_prev{
				left: 0;

				&:before{
					left: -75%;
				}

				&:after{
					@include sprite-ico-carousel-arrow-prev-mob(true);
					left: -10px;

					@include breakPoint($breakPointMob){
						@include sprite-ico-carousel-arrow-prev-mob2;
						left: -20px;
					}

					@include breakPoint($breakPointTab1){
						@include sprite-ico-carousel-arrow-prev-tab;
						left: -15px;
					}
				}

				.touch &,
				&:hover{

					&:before{
						left: -50%;
					}

					&:after{
						left: 7px;

						@include breakPoint($breakPointMob){
							left: 10px;
						}

						@include breakPoint($breakPointTab1){
							//left: 10px;
						}
					}
				}

			}

			&_next{
				right: 0;

				&:before{
					right: -75%;
				}

				&:after{
					@include sprite-ico-carousel-arrow-next-mob(true);
					right: -10px;

					@include breakPoint($breakPointMob){
						@include sprite-ico-carousel-arrow-next-mob2;
						right: -20px;
					}

					@include breakPoint($breakPointTab1){
						@include sprite-ico-carousel-arrow-next-tab;
						right: -15px;
					}
				}

				.touch &,
				&:hover{

					&:before{
						right: -50%;
					}

					&:after{
						right: 7px;

						@include breakPoint($breakPointMob){
							right: 10px;
						}

						@include breakPoint($breakPointTab1){
							//right: 8px;
						}
					}
				}

			}
		}
	}

	&__item{
		display: inline-block;
		vertical-align: top;
		width: 100%;

		@include breakPoint($breakPointTab1){
			width: 50%;
		}

		@include breakPoint($breakPointTab2){
		}

		@include breakPoint($breakPointDesk1){
			width: 240px;

			&_big{
				width: 480px;
			}
		}
		@include breakPoint($breakPointDesk2){
			width: 250px;

			&_big{
				width: 500px;
			}
		}
		@include breakPoint($breakPointDesk3){
			width: 320px;

			&_big{
				width: 640px;
			}
		}

		a{
			&:hover{
				color: $colorYellow;
			}
		}

		&-inner{
			background: #fff;
			padding: 50px 10px;
			@include box-sizing();
			font-size: 14px;
			white-space: normal;
			position: relative;
			height: $cardHeightMob1 - 20px;
			margin: 10px;

			@include breakPoint($breakPointMob){
				padding: 70px 20px;
				font-size: 24px;
				height: $cardHeightMob2 - 20px;
			}

			@include breakPoint($breakPointTab1){
				padding: 0 20px 65px;
				font-size: 16px;
				height: $cardHeightTab1 - 20px;
			}

			@include breakPoint($breakPointTab2){
				height: $cardHeightTab2 - 20px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 13px;
				padding-bottom: 50px;
				height: $cardHeightDesk1 - 20px;
			}

			@include breakPoint($breakPointDesk2){
				height: $cardHeightDesk2 - 20px;
			}

			@include breakPoint($breakPointDesk3){
				height: $cardHeightDesk3 - 20px;
			}



			.card__item_tag &,
			.card__item_big &{
				color: #fff;
				padding-top: 20px;

				@include breakPoint($breakPointMob){
					padding-top: 30px;
				}
			}

			.card__item_tag &{
				text-align: center;
			}

		}

		&-image{
			background-size: cover;
			width: 88px;
			height: 55px;
			float: left;

			@include breakPoint($breakPointMob){
				width: 175px;
				height: 110px;
			}

			@include breakPoint($breakPointTab1){
				width: calc(100% + 40px);
				height: 215px;
				margin: 0 -20px 15px;
				float: none;
			}

			@include breakPoint($breakPointDesk1){
				height: 150px;
			}

			@include breakPoint($breakPointDesk3){
				height: 195px;
			}

			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&.obj-fit{
				opacity: 0;
			}

			.card__item_tag &{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: auto;
				height: auto;
				margin: 0;
			}


			.card__item_big &{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: auto;
				height: auto;
				margin: 0;

				&:before{
					content: '';
					display: block;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					height: 100%;
					background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
					background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
					background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
					background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
				}
			}
		}


		&-info{

			.card__item_big &{
				position: absolute;
				bottom: 50px;
				right: 10px;
				left: 10px;

				@include breakPoint($breakPointMob){
					right: 20px;
					left: 20px;
				}
			}

			.card__item_tag &{
				position: relative;
			}
		}

		&-subtitle{
			position: absolute;
			top: 20px;
			left: 10px;

			@include breakPoint($breakPointMob){
				left: 20px;
			}

			@include breakPoint($breakPointTab1){
				position: static;
				margin-bottom: 15px;
			}

			@include breakPoint($breakPointDesk1){
				margin-bottom: 10px;
			}

			.card__item_big &{
				position: static;
				margin-bottom: 10px;

				@include breakPoint($breakPointMob){
					margin-bottom: 20px;
				}
			}

			.card__item_tag &{
				position: static;
				margin-bottom: 15px;

				@include breakPoint($breakPointMob){
					margin-bottom: 30px;
				}

				@include breakPoint($breakPointTab1){
					margin-bottom: 110px;
				}

				@include breakPoint($breakPointDesk1){
					margin-bottom: 80px;
				}

				@include breakPoint($breakPointDesk3){
					margin-bottom: 100px;
				}
			}

		}

		&-title{
			font-family: $fontTitle;
			font-size: 16px;
			margin-left: 100px;
			margin-bottom: 10px;
			height: 60px;
			overflow: hidden;
			color: $colorBlack;
			@include textOverflow(3);

			@include breakPoint($breakPointMob){
				font-size: 30px;
				margin-left: 195px;
				height: 110px;
				margin-bottom: 20px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 22px;
				margin-left: 0;
				height: auto;
				max-height: 80px;
				-webkit-line-clamp: 3;
				margin-bottom: 10px;
			}

			@include breakPoint($breakPointTab2){
				max-height: 53px;
				-webkit-line-clamp: 2;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 18px;
				max-height: 65px;
				-webkit-line-clamp: 3;
			}

			.card__item_tag &,
			.card__item_big &{
				color: #fff;

				@include breakPoint(0, $breakPointMob - 1px){
					margin: 0 0 15px;
					font-size: 18px;
					max-height: 65px;
				}

				@include breakPoint($breakPointMob, $breakPointTab1 - 1px){
					font-size: 30px;
					margin: 0 0 30px;
					max-height: 115px;
				}

				@include breakPoint($breakPointTab1){
					margin-bottom: 20px;
				}

				@include breakPoint($breakPointDesk1){
					font-size: 22px;
					max-height: 80px;
				}

				@include breakPoint($breakPointDesk3){
					font-size: 24px;
					max-height: 85px;
				}
			}
		}

		&-text{
			height: 50px;
			@include textOverflow(3);
			font-weight: 300;

			@include breakPoint($breakPointMob){
				height: 85px;
			}

			@include breakPoint($breakPointTab1){
				height: 60px;
				-webkit-line-clamp:3;
			}

			@include breakPoint($breakPointDesk1){
				line-height: 1.5;
				height: 60px;
				-webkit-line-clamp: 3;
			}

			@include breakPoint($breakPointDesk2){

			}

			@include breakPoint($breakPointDesk3){

			}

			.card__item_big &{
				display: none;
			}

			.card__item_tag &{

				@include breakPoint($breakPointDesk1){
					line-height: 1.8;
					height: 85px;
				}

				@include breakPoint($breakPointTab1){
					line-height: 1.8;
					height: 70px;
				}
			}

			.tag{
				display: inline-block;
				white-space: nowrap;

				&:before{
					content: '#';
					color: $colorYellow;
					margin: 0 5px;
				}
			}
		}

		&-control{
			font-size: 16px;

			@include breakPoint($breakPointMob){
				font-size: 24px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 16px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 14px;
			}

			&-more{
				color: $colorYellow;
				position: absolute;
				left: 10px;
				bottom: 15px;

				.card__item_tag &,
				.card__item_big &{
					color: #fff;
				}

				@include breakPoint($breakPointMob){
					left: 20px;
					bottom: 25px;
				}

				@include breakPoint($breakPointDesk1){
					bottom: 20px;
				}
			}

			&-like{
				position: absolute;
				right: 10px;
				bottom: 15px;
				cursor: pointer;

				@include breakPoint($breakPointMob){
					right: 20px;
					bottom: 25px;
				}

				@include breakPoint($breakPointDesk1){
					bottom: 20px;
				}

				.card__item_big &{

				}

				&:before{
					content: '';
					display: inline-block;
					vertical-align: middle;
					@include sprite-ico-like-mob-grey(true);
					margin-right: 5px;
					margin-top: -3px;

					.card__item_tag &,
					.card__item_big &{
						@include sprite-ico-like-mob-white;
					}

					@include breakPoint($breakPointMob){
						margin-right: 15px;
						margin-top: -5px;
						@include sprite-ico-like-tab-grey;

						.card__item_tag &,
						.card__item_big &{
							@include sprite-ico-like-tab-white;
						}
					}

					@include breakPoint($breakPointDesk1){
						margin-right: 5px;
						margin-top: -3px;
						@include sprite-ico-like-mob-grey;

						.card__item_tag &,
						.card__item_big &{
							@include sprite-ico-like-mob-white;
						}
					}
				}

				&:hover,
				&.is-active{
					color: $colorYellow;

					&:before{
						@include sprite-ico-like-mob-yellow;

						@include breakPoint($breakPointMob){
							@include sprite-ico-like-tab-yellow;
						}

						@include breakPoint($breakPointDesk1){
							@include sprite-ico-like-mob-yellow;
						}
					}
				}

			}

		}
	}

	&__message{
		height: calc(100vh - 75px);
		text-align: center;

		&:after{
			content: '';
			display: inline-block;
			height: 80%;
			vertical-align: middle;
		}

		&-inner{
			display: inline-block;
			vertical-align: middle;
			line-height: 1.5;
		}

		@include breakPoint($breakPointMob){
			height: calc(100vh - 130px);
		}

		@include breakPoint($breakPointDesk1){
			height: calc(100vh - 90px);
		}

		&-title{
			font-size: 20px;
			color: $colorBlack;
			font-family: $fontTitle;
			margin-bottom: 0.5em;

			@include breakPoint($breakPointMob){
				font-size: 30px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 24px;
			}
		}

		&-text{
			font-size: 16px;

			@include breakPoint($breakPointMob){
				font-size: 24px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 20px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 13px;
			}

		}
	}
}



.newsfeed{
	background: #fff;
	padding: 20px 0;
	position: relative;

	@include breakPoint($breakPointDesk1){
		padding: 20px 20px 0;
	}

	.mCustomScrollBox.mCSB_horizontal{
		margin-bottom: -10px;
		padding-bottom: 10px;

		@include breakPoint($breakPointMob){
			margin-bottom: 0px;
			padding-bottom: 20px;
		}
	}

	.mCSB_scrollTools.mCSB_scrollTools_horizontal{
		left: 10px;
		right: 10px;

		@include breakPoint($breakPointMob){
			left: 20px;
			right: 20px;
		}
	}

	.mCustomScrollBox.mCSB_vertical{
		margin-right: -10px;
		padding-right: 10px;
	}

	.mCSB_scrollTools.mCSB_scrollTools_vertical{
		right: 0;
		bottom: 20px;

	}

	a{
		&:hover{
			color: $colorYellow;
		}
	}

	&__title{
		font-size: 16px;
		font-weight: 500;
		color: $colorBlack;
		margin-bottom: 11px;
		padding: 0 10px;

		@include breakPoint($breakPointMob){
			padding: 0 20px;
			font-size: 30px;
		}


		@include breakPoint($breakPointTab1){
			font-size: 22px;
		}

		@include breakPoint($breakPointDesk1){
			font-size: 13px;
			padding: 0;
			margin-bottom: 0;
			height: 25px;
		}
	}

	&__list{
		font-size: 0;
		white-space: nowrap;

		position: relative;


		@include breakPoint($breakPointDesk1){
			max-height: 715px;
			white-space: normal;

			&:before,
			&:after{
				content: '';
				display: block;
				opacity: 1;
				position: absolute;
				left: 0;
				width: 100%;
				height: 100px;
				pointer-events: none;
				z-index: 1;
				@include transition(opacity 0.3s ease);
			}

			&:before{
				top: 0;
				background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			}

			&:after{
				bottom: 0;
				background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			}

			&.is-scrollbar-start{
				&:before{
					opacity: 0;
				}
			}

			&.is-scrollbar-end{
				&:after{
					opacity:0;
				}
			}
		}

		@include breakPoint($breakPointDesk2){
			max-height: 735px;
		}

		@include breakPoint($breakPointDesk3){
			max-height: 835px;
		}
	}

	&__item{
		display: inline-block;
		vertical-align: top;
		width: 145px;
		@include box-sizing();
		padding: 10px;
		border-left: 1px solid $colorBorder;

		@include breakPoint($breakPointMob){
			padding: 20px;
			width: 290px;
		}

		@include breakPoint($breakPointTab1){
			width: 360px;
		}

		@include breakPoint($breakPointDesk1){
			width: auto;
			display: block;
			border-left: 0;
			border-top: 1px solid $colorBorder;
			padding: 15px 0;
		}

		&:first-child{
			border-left: 0;
			border-top: 0;
		}

		&-inner{
			white-space: normal;
		}
		&-subtitle{
			font-size: 14px;
			margin-bottom: 15px;

			@include breakPoint($breakPointMob){
				font-size: 24px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 16px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 13px;
			}
		}
		&-title{
			font-size: 16px;
			color: $colorBlack;
			line-height: 1;
			font-family: $fontTitle;
			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;

			@include breakPoint($breakPointMob){
				font-size: 24px;
				line-height: 1.3;
			}

			@include breakPoint($breakPointTab1){
				font-size: 22px;
				line-height: 1.45;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 14px;
				line-height: 1.2;
			}
		}
	}
}


.greeting{


	//margin-bottom: 140px;
	@include breakPoint(0, $breakPointMob - 1px){

		& + .subscribes{
			padding-top: 0;
		}
	}

	@include breakPoint($breakPointMob){
		margin-bottom: 50px;
	}
	@include breakPoint($breakPointTab2){
		margin-bottom: 90px;
	}


	@include breakPoint($breakPointDesk1){
		overflow: hidden;
		padding-bottom: 10px;
	}

	.inner{
		@include breakPoint($breakPointTab1){
			padding-top: 115px;
		}

		@include breakPoint($breakPointTab2){
			padding-top: 30px;
		}

		@include breakPoint($breakPointDesk1){

		}

	}

	.bubble{

		@include breakPoint($breakPointDesk1){
			width: 1800px;
			height: 1800px;
			margin-left: -900px;
			margin-top: -900px;
			top: 0;
			left: 0;
		}
	}

	&__welcome{
		text-align: center;
		padding: 90px 0 80px;

		@include breakPoint($breakPointMob){
			padding: 180px 0 205px;
		}

		@include breakPoint($breakPointTab1){
			padding: 0;
			height: 470px;
			text-align: left;
			float: left;
			width: calc(50% - 10px);
			font-size: 0;

			&:before{
				content: '';
				display: inline-block;
				vertical-align: middle;
				height: 100%;
			}
		}

		@include breakPoint($breakPointDesk1){
			position: relative;
		}


		&-inner{
			@include breakPoint($breakPointTab1){
				display: inline-block;
				vertical-align: middle;
			}
		}

		&-title{
			font-size: 30px;
			font-weight: 500;
			color: $colorBlack;
			margin-bottom: 20px;

			@include breakPoint($breakPointMob){
				font-size: 50px;
				margin-bottom: 70px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 36px;
				margin-bottom: 35px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 30px;
				line-height: 1.63;
			}

			@include breakPoint($breakPointDesk2){
				font-size: 40px;
				line-height: 1.2;
			}

			@include breakPoint($breakPointDesk3){
				font-size: 50px;
				line-height: 1;
			}

			span{
				color: $colorYellow;
			}
		}

		&-text{
			font-size: 20px;
			margin-bottom: 35px;

			@include breakPoint($breakPointMob){
				font-size: 30px;
				margin-bottom: 70px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 20px;
				margin-bottom: 35px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 18px;
			}

			@include breakPoint($breakPointDesk2){
				font-size: 24px;
			}

			@include breakPoint($breakPointDesk3){
				font-size: 28px;
			}

		}

		&-btn{
			margin-top: 15px;

			@include breakPoint($breakPointMob){
				margin-top: 30px;
			}

			@include breakPoint($breakPointTab1){
				margin-top: 15px;
			}

			.btn{
				display: block;
				width: 245px;
				margin: 0 auto;

				@include breakPoint($breakPointMob){
					width: 490px;
				}

				@include breakPoint($breakPointTab1){
					width: 320px;
					display: inline-block;
					margin: 0;
				}

				@include breakPoint($breakPointDesk1){
					width: auto;
				}
			}
		}
	}


	&__sample{

		@include breakPoint($breakPointTab1){
			float: right;
			width: calc(50% - 10px);
			@include box-sizing();
		}

		@include breakPoint($breakPointTab2){
			padding-left: 100px;
		}

		@include breakPoint($breakPointDesk1){
			padding-left: 0;
		}

		@include breakPoint($breakPointDesk2){
			//padding-left: 80px;
		}

		&-plate{
			height: 270px;
			position: absolute;
			right: 0;
			left: 50%;
			margin-left: -80px;
			background: url("..//img/bg-plate.svg") no-repeat 0 0;
			background-size: auto 100%;

			@include breakPoint($breakPointMob){
				height: 490px;
				margin-left: -170px;
			}

			@include breakPoint($breakPointTab1){
				margin-left: 40px;
			}

			@include breakPoint($breakPointTab2){
				margin-left: 170px;
			}

			@include breakPoint($breakPointDesk1){
				margin-left: 0;
			}

			@include breakPoint($breakPointDesk2){
				margin-left: 80px;
			}

			@include breakPoint($breakPointDesk3){
				margin-left: 10px;
				height: 670px;
			}

		}

		&-cards{
			position: relative;

			.card{
				&__list{
					white-space: nowrap;
					padding: 32px 0 10px;
					margin: 0;

					@include breakPoint($breakPointMob){
						padding: 60px 0 20px;
					}

					@include breakPoint($breakPointTab1){
					}

					@include breakPoint($breakPointDesk1){
						margin-left: -120px;
					}

					@include breakPoint($breakPointDesk2){
						margin-left: 0;
					}

					@include breakPoint($breakPointDesk3){
						margin-left: -80px;
						padding-bottom: 30px;
					}
				}

				&__item{

					display: none;
					overflow: hidden;

					&:first-child{
						display: inline-block;
					}

					@include breakPoint($breakPointTab1){
						width: 350px;
					}

					@include breakPoint($breakPointDesk1){
						display: inline-block;
						margin-right: 20px;
						width: 220px;
					}
					@include breakPoint($breakPointDesk3){
						margin-right: 30px;
						width: 300px;

					}

					&-inner{
						height: auto;
						margin: 0;
					}

					&-title{

						@include breakPoint($breakPointTab1){
							height: 55px;
							-webkit-line-clamp: 2;
						}

						@include breakPoint($breakPointTab2){
							height: 43px;
						}

					}

					&-text{

						height: 32px;
						-webkit-line-clamp:2;

						@include breakPoint($breakPointMob){
							height: 60px;
						}

					}
				}
			}

		}

		&-quote{
			padding: 30px 13px 15px;
			@include border-radius(15px);
			@include box-shadow();
			position: relative;
			width: 170px;
			@include box-sizing();
			background: #fff;

			@include breakPoint($breakPointMob){
				width: 340px;
				padding: 55px 20px 25px;
			}

			@include breakPoint($breakPointTab1){
				margin-top: -70px;
				margin-left: -50px;
				width: 320px;
			}

			@include breakPoint($breakPointDesk1){
				margin-left: -120px;
				width: 340px;
				margin-top: 0;
			}

			@include breakPoint($breakPointDesk2){
				margin-left: 0;
			}

			@include breakPoint($breakPointDesk3){
				width: 460px;
				margin-left: -80px;
			}

			&-text{
				font-size: 14px;
				font-family: $fontTitle;
				color: $colorBlack;
				text-align: center;
				font-style: italic;
				@include textOverflow(2);

				@include breakPoint($breakPointMob){
					font-size: 20px;
					-webkit-line-clamp: 3;
				}

				@include breakPoint($breakPointTab1){
					font-size: 16px;
				}

				@include breakPoint($breakPointDesk1){
					font-size: 14px;
				}

				@include breakPoint($breakPointDesk3){
					font-size: 18px;
				}
			}

			&:before{
				content: '';
				display: inline-block;
				@include sprite-ico-quote-mob-yellow(true);
				position: absolute;
				top: 10px;
				left: 50%;
				margin-left: -10px;

				@include breakPoint($breakPointMob){
					top: 20px;
					@include sprite-ico-quote-tab-yellow
				}
			}

		}

		&-icons{
			position: absolute;
			margin-left: 145px;
			margin-top: 5px;

			@include breakPoint($breakPointMob){
				margin-left: 360px;
				margin-top: 25px;
			}

			@include breakPoint($breakPointTab1){
				margin-left: 125px;
				margin-top: -335px;
			}

			@include breakPoint($breakPointDesk1){
				margin-left: 240px;
				margin-top: 0;
			}

			@include breakPoint($breakPointDesk2){
				margin-left: 360px;
			}

			@include breakPoint($breakPointDesk3){
				margin-left: 430px;
				margin-top: 25px;
			}
		}

		&-icon{

			width: 46px;
			height: 46px;
			@include border-radius(50%);
			background: #fff;
			@include box-shadow();
			position: absolute;
			z-index: 1;

			@include breakPoint($breakPointMob){
				width: 72px;
				height: 72px;
			}

			@include breakPoint($breakPointDesk3){
				width: 90px;
				height: 90px;
			}

			&:before{
				content: '';
				display: block;
				position: absolute;
				margin: auto;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}

			&_search{

				@include breakPoint($breakPointMob){

				}

				@include breakPoint($breakPointTab1){

				}

				&:before{
					@include sprite-ico-search-mob-grey(true);

					@include breakPoint($breakPointMob){
						@include sprite-ico-search-tab-grey
					}
				}
			}

			&_like{
				margin-left: 50px;
				margin-top: 30px;

				@include breakPoint($breakPointMob){
					margin-top: 40px;
					margin-left: 75px;
				}

				@include breakPoint($breakPointDesk2){
					margin-top: 60px;
				}


				@include breakPoint($breakPointDesk3){
					margin-top: 0;
					margin-left: 135px;
				}

				&:before{
					@include sprite-ico-like-mob-grey(true);

					@include breakPoint($breakPointMob){
						@include sprite-ico-like-tab-grey;
					}
				}
			}
			&_lock{
				margin-left: 100px;

				@include breakPoint($breakPointMob){
					margin-left: 150px;
				}

				@include breakPoint($breakPointDesk3){
					margin-left: 270px;
				}

				&:before{
					@include sprite-ico-lock-mob-grey(true);

					@include breakPoint($breakPointMob){
						@include sprite-ico-lock-tab-grey;
					}
				}
			}
		}
	}





	&__interest{

		text-align: center;
		padding-top: 70px;
		padding-bottom: 90px;

		@include breakPoint($breakPointMob){
			padding: 140px 0 40px;
			width: 540px;
			margin: 0 auto;
		}

		@include breakPoint($breakPointTab1){
			width: 605px;
		}

		@include breakPoint($breakPointDesk1){
			width: 50%;
			padding: 230px 0 40px 10px;
			@include box-sizing();
			float: right;
			text-align: left;
		}

		@include breakPoint($breakPointDesk2){
			padding-left: 140px;
		}

		@include breakPoint($breakPointDesk3){
			padding-left: 170px;
			padding-top: 400px;
		}

		&-title{
			font-size: 30px;
			font-weight: 500;
			color: $colorBlack;
			margin-bottom: 20px;

			@include breakPoint($breakPointMob){
				font-size: 50px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 36px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 30px;
				line-height: 1.63;
			}

			@include breakPoint($breakPointDesk2){
				font-size: 40px;
				line-height: 1.2;
			}

			@include breakPoint($breakPointDesk3){
				font-size: 50px;
				line-height: 1;
			}

			span{
				color: $colorYellow;
			}
		}
		&-text{
			font-family: $fontTitle;
			margin-bottom: 45px;
			line-height: 1.5;


			@include breakPoint($breakPointDesk1){
				margin-bottom: 30px;
			}
		}

		&-subtitle{
			font-size: 24px;
			font-family: $fontTitle;
			color: $colorBlack;
			margin-bottom: 25px;

			@include breakPoint($breakPointMob){
				font-size: 40px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 20px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 24px;
				margin-bottom: 15px;
			}
		}

		&-tags{


			@include breakPoint($breakPointDesk1){
				font-size: 13px;
			}

			&-item{
				display: inline-block;
				white-space: nowrap;
				margin: 3px 5px;

				span{
					color: $colorYellow;

					@include breakPoint($breakPointDesk1){
						font-size: 18px;
					}
				}
			}
		}
	}


	&__tags{
		text-align: center;

		@include breakPoint($breakPointMob){
			width: 540px;
			margin: 0 auto;
		}

		@include breakPoint($breakPointTab1){
			width: 605px;
			margin: 0 auto;
		}

		@include breakPoint($breakPointDesk1){
			width: 50%;
			padding-right: 10px;
			padding-top: 150px;
			@include box-sizing();
			float: left;
		}

		@include breakPoint($breakPointDesk3){
			padding-top: 300px;
		}


		&-list{
			height: 400px;
			position: relative;

			@include breakPoint($breakPointMob){
				height: 530px;
			}

			@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
				height: 430px;
			}

		}

		&-item{
			width: 126px;
			background: #fff;
			@include border-radius(15px);
			@include box-shadow();
			padding: 20px 10px 18px;
			@include box-sizing();
			position: absolute;
			font-size: 13px;

			@include breakPoint($breakPointMob){
				width: 195px;
				padding: 30px 20px;
				font-size: 20px;
			}

			@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
				width: 155px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 16px;
				padding: 25px 15px;
			}

			&_clean{
				background: none;
				width: auto;
				padding: 0;
				@include box-shadow(none);

				.greeting__tags-image{
					margin: 0;
				}
			}

			&_1{
				top: 0;
				right: 145px;

				@include breakPoint($breakPointMob){
					top: 120px;
					right: 260px;
				}

				@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
					top: 95px;
					right: 275px;
				}

				@include breakPoint($breakPointDesk3){
					right: 385px;
				}
			}

			&_2{
				top: 155px;
				right: 160px;

				@include breakPoint($breakPointMob){
					top: 335px;
					right: 370px;
				}

				@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
					top: 270px;
					right: 360px;
				}

				@include breakPoint($breakPointDesk3){
					right: 500px;
				}
			}

			&_3{
				top: 105px;
				right: 0;

				@include breakPoint($breakPointMob){
					top: 180px;
					right: 20px;
				}

				@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
					top: 145px;
					right: 85px;
				}

				@include breakPoint($breakPointDesk3){
					right: 65px;
				}
			}

			&_4{
				top: 25px;
				right: 35px;

				@include breakPoint($breakPointMob){
					top: 40px;
					right: 135px;
				}

				@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
					top: 35px;
					right: 175px;
				}

				@include breakPoint($breakPointDesk3){
					right: 200px;
				}

			}

			&_5{
				display: none;

				@include breakPoint($breakPointMob){
					display: block;
					top: 0;
					right: 455px;
				}

				@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
					top: 0;
					right: 430px;
				}

				@include breakPoint($breakPointDesk2){
					top: 215px;
					right: 535px;
				}

				@include breakPoint($breakPointDesk3){
					right: 680px;
				}
			}
		}

		&-image{
			width: 60px;
			height: 60px;
			font-size: 0;
			margin: 0 auto 10px;

			@include breakPoint($breakPointMob){
				width: 90px;
				height: 90px;
				margin-bottom: 20px;
			}

			@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
				width: 72px;
				height: 72px;
				margin-bottom: 10px;
			}

			img{
				width: 100%;
				height: 100%;
				display: inline-block;
				@include border-radius(50%);
			}
		}

		&-text{

			span{
				color: $colorYellow;
			}
		}

		&-notice{
			width: 185px;
			background: $colorYellow;
			@include border-radius(15px);
			font-family: $fontTitle;
			font-size: 13px;
			font-style: italic;
			@include box-shadow();
			color: #fff;
			padding: 15px 25px;
			@include box-sizing();
			position: absolute;
			top: 285px;
			right: 0;

			@include breakPoint($breakPointMob){
				width: 280px;
				padding: 25px 40px;
				font-size: 20px;
				top: 425px;
				right: 20px;
			}

			@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
				font-size: 16px;
				width: 230px;
				padding: 20px 30px;
				top: 340px;
				right: 85px;
			}

			@include breakPoint($breakPointDesk3){
				right: 65px;
			}

			i{
				width: 58px;
				height: 58px;
				@include border-radius(50%);
				background: #fff;
				@include box-shadow();
				position: absolute;
				top: -26px;
				left: -24px;

				@include breakPoint($breakPointMob){
					width: 90px;
					height: 90px;
					top: -40px;
					left: -40px;
				}

				@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
					width: 72px;
					height: 72px;
					top: -36px;
					left: -36px;
				}

				&:before{
					content: '';
					@include sprite-ico-bell-mob-yellow(true);
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					margin: auto;

					@include breakPoint($breakPointMob){
						@include sprite-ico-bell-tab-yellow
					}
				}
			}
		}

	}


}

.subscribes{
	overflow: hidden;
	position: relative;
	text-align: center;
	padding-bottom: 50px;
	padding-top: 50px;

	@include breakPoint($breakPointMob, $breakPointTab1 - 1px){
		padding-top: 100px;
	}

	.bubble{
		width: 2473px;
		height: 2473px;
		margin-left: - (2473px / 2);
		top: 0;
		left: 50%;
	}

	.inner{
		@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
			width: 605px;
		}

		@include breakPoint($breakPointDesk1){
			position: relative;
		}

		@include breakPoint($breakPointDesk2){
			width: 1000px;
		}

		@include breakPoint($breakPointDesk3){
			width: 1260px;
		}
	}


	&__title{
		font-size: 30px;
		color: $colorBlack;
		font-weight: 500;
		margin-bottom: 25px;

		@include breakPoint($breakPointMob){
			font-size: 50px;
		}

		@include breakPoint($breakPointTab1){
			font-size: 36px;
		}
	}

	&__text{
		font-family: $fontTitle;
		line-height: 1.5;
		margin-bottom: 45px;
	}

	&__list{
		font-size: 0;

		@include breakPoint($breakPointDesk1){
			margin: 0 -10px;
		}
	}

	&__item{
		display: inline-block;
		vertical-align: top;
		width: 100%;
		margin-bottom: 20px;
		font-size: 16px;

		@include breakPoint($breakPointMob){
			font-size: 24px;
		}

		@include breakPoint($breakPointTab1){
			font-size: 16px;
		}

		@include breakPoint($breakPointDesk1){
			width: 50%;
			text-align: left;
		}

		&-inner{
			background: #fff;
			@include box-shadow();
			@include border-radius(15px);
			padding: 15px 20px;
			position: relative;

			@include breakPoint($breakPointMob){
				padding: 15px 45px;
			}

			@include breakPoint($breakPointTab1){
				padding: 20px 75px;
			}

			@include breakPoint($breakPointDesk1){
				margin: 0 10px;
				padding: 20px 40px;
			}
		}

		&-title{
			font-size: 24px;
			font-weight: 500;
			color: $colorBlack;
			margin: 15px 0;

			@include breakPoint($breakPointMob){
				margin: 20px 0;
				font-size: 40px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 28px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 20px;
			}

			span{
				color: $colorYellow;
			}
		}

		&-price{
			margin: 15px 0;

			@include breakPoint($breakPointMob){
				margin: 20px 0;
				font-size: 40px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 20px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 24px;
			}

			b{
				font-size: 30px;
				font-weight: 500;

				@include breakPoint($breakPointMob){
					font-size: 50px;
				}

				@include breakPoint($breakPointTab1){
					font-size: 36px;
				}

				@include breakPoint($breakPointDesk1){
					font-size: 30px;
				}

				@include breakPoint($breakPointDesk2){
					font-size: 40px;
				}
			}

			span{
				color: $colorYellow;
			}

		}

		&-ribbon{
			display: none;

			@include breakPoint($breakPointDesk1){
				display: block;
				background: $colorYellow;
				color: #fff;
				font-size: 18px;
				position: absolute;
				right: 0;
				height: 42px;
				line-height: 42px;
				padding: 0 25px;

				@include breakPoint($breakPointDesk3){
					font-size: 24px;
					padding: 0 45px;
				}

				&:before{
					content: '';
					display: block;
					position: absolute;
					border: 21px solid transparent;
					border-top-color: $colorYellow;
					border-bottom-color: $colorYellow;
					top: 0;
					left: -21px;
				}
			}
		}

		.form{
			text-align: left;
			padding: 0 15px;
			font-weight: 300;

			@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
				max-width: 340px;
				margin: 0 auto;
			}

			@include breakPoint($breakPointTab1){
				padding: 0;
			}

			&__field{
				margin-bottom: 15px;

				@include breakPoint($breakPointMob){
					margin-bottom: 30px;
				}

				@include breakPoint($breakPointTab1){
					margin-bottom: 20px;
				}
			}

			&__input input[type="checkbox"] ~ .form__label {

				@include breakPoint($breakPointTab1, $breakPointDesk1 - 1px){
					padding: 12px 0 11px 75px;
					font-size: 20px;
				}

				@include breakPoint($breakPointDesk1){
					font-size: 13px;
				}
			}


			a{
				color: $colorYellow;
			}

			.btn{
				width: 100%;
			}
		}

		&-subtitle,
		&-text{
			font-weight: 300;
			margin: 15px 0;

			@include breakPoint($breakPointMob){
				margin: 30px 0;
			}

			@include breakPoint($breakPointTab1){
				margin: 20px 0;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 13px;
			}

			span{
				color: $colorYellow;
			}

			ul{
				text-align: left;
			}
			li{
				margin-bottom: 20px;
				position: relative;
				padding-left: 20px;

				@include breakPoint($breakPointMob){
					padding-left: 35px;
					margin-bottom: 30px;
				}

				@include breakPoint($breakPointTab1){
					margin-bottom: 20px;
				}

				@include breakPoint($breakPointDesk1){
					padding-left: 20px;
					margin-bottom: 15px;
				}

				&:before{
					content: '';
					width: 6px;
					height: 6px;
					@include border-radius(50%);
					background: $colorYellow;
					position: absolute;
					top: 6px;
					left: 0;

					@include breakPoint($breakPointMob){
						width: 12px;
						height: 12px;
					}

					@include breakPoint($breakPointTab1){
						width: 8px;
						height: 8px;
					}

					@include breakPoint($breakPointDesk1){
						width: 6px;
						height: 6px;
					}
				}
			}
		}
		&-btn{
			padding: 0 15px;
			margin: 15px 0;

			@include breakPoint($breakPointMob){
				margin: 30px 0;
			}

			@include breakPoint($breakPointTab1){
				margin: 20px 0;
			}

			@include breakPoint($breakPointDesk1){
				padding: 0;
			}

			.btn{
				display: block;
				width: 100%;

				@include breakPoint($breakPointDesk1){
					width: auto;
					display: inline-block;
				}
			}
		}


		&_welcome{

			@include breakPoint($breakPointDesk1){
				.subscribes__item-inner{
					padding-left: 80px;
					padding-right: 80px;
				}

				.subscribes__item-text{
					text-align: center;
					line-height: 1.5;
				}

				.subscribes__item-subtitle{
					float: left;
					text-align: left;
					line-height: 1.5;
				}

				.subscribes__item-price{
					float: right;
					line-height: 1;
				}
			}
		}

	}
}

.bubble{

	display: none;

	@include breakPoint($breakPointDesk1){
		display: block;
		position: absolute;
		@include border-radius(50%);
		background: rgba(#fff, 0.3);
	}
}




.pagin{

	&__list{
		font-size: 0;
		margin: -1px;

		@include breakPoint($breakPointMob){
			margin: -3px;
		}

		@include breakPoint($breakPointTab1){
			margin: -5px;
		}
	}

	&__item,
	&__dot,
	&__btn{
		display: inline-block;
		vertical-align: middle;
		padding: 0 10px;
		text-align: center;
		line-height: 42px;
		min-width: 42px;
		font-size: 16px;
		margin: 1px;
		@include box-sizing();

		@include breakPoint($breakPointMob){
			font-size: 24px;
			line-height: 66px;
			min-width: 66px;
			margin: 3px;
		}

		@include breakPoint($breakPointTab1){
			font-size: 22px;
			line-height: 52px;
			min-width: 52px;
			margin: 5px;
		}

		@include breakPoint($breakPointDesk1){
			line-height: 42px;
			min-width: 42px;
			font-size: 16px;
		}
	}
	&__item{
		cursor: pointer;
		background: #fff;

		&.is-active{
			color: $colorYellow;
			background: #fff4d9;
		}

		&:hover{
			background: #fff4d9;
		}
	}
	&__dot{

	}

	&__btns{

		width: 100%;
		margin-top: 20px;
		display: table;
		border-collapse: separate ;

		@include breakPoint($breakPointMob){
			margin-top: 44px;
		}

		@include breakPoint($breakPointTab1){
			margin-top: 30px;
		}

		@include breakPoint($breakPointTab2){
			margin: 5px;
			width: auto;
			display: inline-table;
			vertical-align: middle;
		}
	}

	&__btn{
		background: #fff;
		cursor: pointer;
		display: table-cell;
		width: 50%;
		border: 1px solid $colorBG;
		padding: 0 20px;

		@include breakPoint($breakPointMob){
			border-width: 3px;
		}

		@include breakPoint($breakPointTab1){
			border-width: 5px;
		}

		&:hover{
			background: #fff4d9;
		}

	}
}



.section{
	&__head{
		@include clearfix;
		padding: 20px 0 15px;

		&-title{
			font-family: $fontTitle;
			float: left;
			color: $colorBlack;
			font-size: 20px;

			@include breakPoint($breakPointMob){
				font-size: 36px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 30px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 24px;
			}

			span{
				color: $colorGrey;

				@include breakPoint($breakPointDesk1){
					display: none;
				}
			}
		}

		&-aside{
			float: right;

			@include breakPoint($breakPointDesk1){
				margin-top: -5px;
			}

			&-count{
				display: none;

				@include breakPoint($breakPointDesk1){
					display: inline-block;
					vertical-align: middle;
				}
			}

			&-btn{
				font-size: 0;
				width: 30px;
				height: 26px;
				position: relative;
				display: block;


				@include breakPoint($breakPointMob){
					width: 50px;
					height: 44px;
				}

				@include breakPoint($breakPointTab1){
					width: 50px;
					height: 32px;
				}

				@include breakPoint($breakPointDesk1){
					display: inline-block;
					vertical-align: middle;
					font-size: 13px;
					margin-left: 20px;
					width: auto;
					height: 34px;
					@include box-sizing();
					border: 1px solid $colorBorder;
					@include border-radius(100px);
					line-height: 32px;
					padding: 0 35px;

					&:hover{
						border-color: $colorYellow;
						color: $colorYellow;
					}
				}

				&:before{
					content: '';
					position: absolute;
					display: block;
					top: 50%;
					right: 0;
					margin-top: -10px;
					@include sprite-ico-arrow-more-mob(true);


					@include breakPoint($breakPointMob){
						margin-top: -15px;
						@include sprite-ico-arrow-more-mob2;
					}

					@include breakPoint($breakPointTab1){
						margin-top: -12px;
						@include sprite-ico-arrow-more-tab;
					}

					@include breakPoint($breakPointDesk1){
						display: none;
					}
				}
			}
		}
	}
}

.section-part{
	border-top: 1px solid $colorYellow;
	position: relative;

	@include breakPoint($breakPointMob){
		border-top-width: 2px;
	}
}

.section-announce{
	&__content{

		margin-bottom: 20px;
		overflow: hidden;

		.card{
			&__list{
				max-height: $cardHeightMob1 * 2;

				@include breakPoint($breakPointMob){
					max-height: $cardHeightMob2 * 2;
				}

				@include breakPoint($breakPointTab1){
					max-height: $cardHeightTab1 * 2;
				}

				@include breakPoint($breakPointTab2){
					max-height: $cardHeightTab2 * 2;
				}

				@include breakPoint($breakPointDesk1){
					max-height: $cardHeightDesk1 * 2;
				}

				@include breakPoint($breakPointDesk2){
					max-height: $cardHeightDesk2 * 2;
				}

				@include breakPoint($breakPointDesk3){
					max-height: $cardHeightDesk3 * 2;
				}
			}
		}



		@include breakPoint($breakPointDesk1){
			margin-bottom: 0;
			float: right;
			width: calc(100% - 240px);
		}

		@include breakPoint($breakPointDesk2){
			width: calc(100% - 250px);


		}

		@include breakPoint($breakPointDesk3){
			width: calc(100% - 320px);
		}


		& + .section-announce__aside{
			.newsfeed{
				@include breakPoint($breakPointDesk1){
				}

				@include breakPoint($breakPointDesk2){
				}

				@include breakPoint($breakPointDesk3){
				}
			}
		}
	}

	&__aside{

		@include breakPoint($breakPointDesk1){
			float: left;
			width: 220px;
		}

		@include breakPoint($breakPointDesk2){
			width: 230px;
		}

		@include breakPoint($breakPointDesk3){
			width: 300px;
		}
	}
}



.locked{
	background: rgba(#fff, 0.6);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;

	&__ico{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		@include sprite-ico-lock-mob-closed(true);
		background-color: #fff;
		@include border-radius(50%);
		@include box-shadow();
		cursor: pointer;

		@include breakPoint($breakPointMob){
			@include sprite-ico-lock-mob2-closed;
		}

		@include breakPoint($breakPointTab1){
			@include sprite-ico-lock-tab-closed;
		}

		@include breakPoint($breakPointTab2){

		}

		@include breakPoint($breakPointDesk1){
			@include sprite-ico-lock-desk-closed;
		}

		@include breakPoint($breakPointDesk2){

		}

		@include breakPoint($breakPointDesk3){

		}

		&:hover{
			background-color: #fff4d9;
		}
	}

	&__btn{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: #fff;
		color: $colorYellow;
		text-align: center;
		cursor: pointer;
		height: 42px;
		line-height: 42px;
		margin-bottom: -42px;
		@include transition();

		@include breakPoint($breakPointMob){
			font-size: 24px;
			height: 65px;
			line-height: 65px;
			margin-bottom: -65px;
		}

		@include breakPoint($breakPointTab1){
			font-size: 20px;
			height: 52px;
			line-height: 52px;
			margin-bottom: -52px;
		}

		@include breakPoint($breakPointDesk1){
			font-size: 13px;
			height: 60px;
			line-height: 60px;
			margin-bottom: -60px;
		}


		&:hover{
			background-color: #fff4d9;
		}


		.newsfeed &{
		}
	}


	&:hover{

		.locked__ico{
			@include sprite-ico-lock-mob-opened;

			@include breakPoint($breakPointMob){
				@include sprite-ico-lock-mob2-opened;
			}

			@include breakPoint($breakPointTab1){
				@include sprite-ico-lock-tab-opened;
			}

			@include breakPoint($breakPointDesk1){
				@include sprite-ico-lock-desk-opened;
			}

		}


		.locked__btn{
			margin-bottom: 0;

			.newsfeed &{
				bottom: 50%;
				margin-bottom: -120px;
			}
		}
	}
}

.section-back{
	border-bottom: 1px solid $colorBorder;
	margin: 0;
	padding: 20px 0;

	.back{
		font-size: 14px;
		display: inline-block;
		cursor: pointer;

		@include breakPoint($breakPointMob){
			font-size: 24px;
		}

		@include breakPoint($breakPointTab1){
			font-size: 16px;
		}

		&:before{
			content: '';
			display: inline-block;
			vertical-align: middle;
			@include sprite-ico-arrow-back-mob(true);
			margin-right: 10px;


			@include breakPoint($breakPointMob){
				margin-top: -5px;
				margin-right: 20px;
				@include sprite-ico-arrow-back-mob2;
			}

			@include breakPoint($breakPointTab1){
				margin-top: -2px;
				@include sprite-ico-arrow-back-tab;
			}

			@include breakPoint($breakPointDesk1){
				@include sprite-ico-arrow-back-mob;
			}
		}

		&:hover{
			color: $colorYellow;

			&:before{

				@include sprite-ico-arrow-back-mob-yellow;


				@include breakPoint($breakPointMob){
					@include sprite-ico-arrow-back-mob2-yellow;
				}

				@include breakPoint($breakPointTab1){
					@include sprite-ico-arrow-back-tab-yellow;
				}
				@include breakPoint($breakPointDesk1){
					@include sprite-ico-arrow-back-mob-yellow;
				}

			}
		}
	}
}

.section-article{
	background: #fff;
	margin-top: 0;
	padding-top: 10px;

	@include breakPoint($breakPointMob){
		padding-top: 30px;
	}

	@include breakPoint($breakPointTab1){
		padding-top: 20px;
	}

	@include breakPoint($breakPointDesk1){
		background: none;
	}


	.article{
		.article__info{

			@include breakPoint($breakPointDesk1){
				display: none;
			}
		}
		&-aside{

			@include breakPoint($breakPointDesk1){
				display: block;
			}

			.article__info{

			}


			.article__head{
				display: none;


				@include breakPoint($breakPointDesk2){
					display: block;
				}

				&-likes{
					display: block;
					float: none;
				}
			}
		}
	}

	&__content{

		@include breakPoint($breakPointDesk1){
			background: #fff;
			float: left;
			width: calc(100% - 240px);
			padding: 20px 40px;
			@include box-sizing();
		}

		@include breakPoint($breakPointDesk2){
			width: calc(100% - 250px);

			.article{
				width: 665px;
				float: left;

				&-aside{
					float: right;
					width: 195px;
				}
			}

		}

		@include breakPoint($breakPointDesk3){
			width: calc(100% - 320px);

			.article{
				width: 860px;

				&-aside{
					width: 240px;
				}
			}
		}


	}

	&__aside{
		display: none;
		padding-bottom: 20px;



		@include breakPoint($breakPointDesk1){
			margin-left: calc(100% - 220px);
			display: block;
		}

		@include breakPoint($breakPointDesk2){
			margin-left: calc(100% - 230px);
		}

		@include breakPoint($breakPointDesk3){
			margin-left: calc(100% - 300px);
		}

	}

	.poster{
		overflow: hidden;
		margin-bottom: 20px;

		img{
			width: 100%;
			display: block;
		}

	}
}


.article{

	color: $colorBlack;
	font-family: $fontTitle;
	line-height: 1.25;



	@include breakPoint($breakPointTab1, $breakPointTab2 - 1px){
		width: 605px;
		margin-right: auto;
		margin-left: auto;
	}
	@include breakPoint($breakPointTab2, $breakPointDesk1 - 1px){
		width: 610px;
		margin-right: auto;
		margin-left: auto;
	}


	&-aside{
		display: none;
	}

	.foreword{
		font-family: $fontMain;

		@include breakPoint($breakPointTab1){
			font-size: 18px;
		}

		@include breakPoint($breakPointDesk1){
			font-size: 16px;
		}
	}

	h1, h2, h3{
		font-size: 20px;
		margin: 30px 0 15px;

		@include breakPoint($breakPointMob){
			font-size: 30px;
			margin: 50px 0 30px;
		}

		@include breakPoint($breakPointTab1){
			font-size: 22px;
			margin: 30px 0 15px;
		}

		&:first-child{
			margin-top: 0;
		}
	}

	img{
		max-width: 100%;
		display: block;
	}

	p{
		margin: 15px 0;

		@include breakPoint($breakPointMob, $breakPointTab1 - 1px){
			margin: 30px 0;
		}

		@include breakPoint($breakPointTab1){
			margin: 20px 0;
		}


		b{
			font-weight: 600;
		}

		a{
			color: $colorYellow;
		}
	}


	q{
		display: block;
		text-align: center;
		font-size: 18px;
		color: $colorBlack;
		font-style: italic;
		border-top: 1px solid $colorBorder;
		margin: 45px 0 40px;
		padding: 20px;
		position: relative;

		@include breakPoint($breakPointMob, $breakPointTab1 - 1px){
			font-size: 26px;
			margin: 75px 0 70px;
			padding: 50px;
		}

		@include breakPoint($breakPointTab1){
			margin: 50px 0 45px;
			padding: 40px 100px;
		}

		&:before{
			content: '';
			position: absolute;
			display: block;
			width: 70px;
			height: 30px;
			background: #fff;
			top: -15px;
			left: 50%;
			margin-left: -35px;
		}

		&:after{
			content: '';
			@include sprite-ico-quote-mob-yellow(true);
			display: block;
			position: absolute;
			top: -9px;
			left: 50%;
			margin-left: -10px;

			@include breakPoint($breakPointMob){
				@include sprite-ico-quote-tab-yellow;
				margin-left: -15px;
				top: -12px;
			}
		}


		&.info{
			&:after{
				@include sprite-ico-quote-info-mob-yellow;
				top: -13px;

				@include breakPoint($breakPointMob){
					@include sprite-ico-quote-info-mob2-yellow;
					top: -20px;
				}
			}
		}
	}


	li{
		padding-left: 25px;
		margin: 15px 0;
		position: relative;
		color: $colorText;
		font-family: $fontTitle;

		@include breakPoint($breakPointMob){
			padding-left: 45px;
		}
		@include breakPoint($breakPointTab1){
			padding-left: 30px;
			margin: 20px 0;
		}
	}

	ul{
		margin-bottom: 20px;

		@include breakPoint($breakPointMob){
			margin-bottom: 40px;
		}
		@include breakPoint($breakPointTab1){
			margin-bottom: 30px;
		}

		li{

			&:before{
				content: '';
				display: block;
				width: 6px;
				height: 6px;
				position: absolute;
				@include border-radius(50%);
				background: $colorYellow;
				top: 6px;
				left: 0;

				@include breakPoint($breakPointMob){
					width: 10px;
					height: 10px;
					top: 10px;
				}
				@include breakPoint($breakPointTab1){
					width: 8px;
					height: 8px;
					top: 6px;
				}
			}
		}
	}

	ol{
		counter-reset: articleList;

		li{
			&:before{
				counter-increment: articleList;
				content: counter(articleList);
				display: block;
				width: 6px;
				height: 6px;
				position: absolute;
				@include border-radius(50%);
				color: $colorYellow;
				top: 0;
				left: 0;
				font-family: $fontMain;

				@include breakPoint($breakPointTab1){
					font-size: 20px;
					top: -2px;
				}
			}
		}
	}


	table{
		font-size: 14px;
		color: $colorText;
		width: 100%;
		margin: 20px 0;

		@include breakPoint($breakPointMob){
			font-size: 20px;
			margin: 40px 0;
		}

		@include breakPoint($breakPointTab1){
			font-size: 16px;
			margin: 20px 0;
		}
	}

	th{
		font-weight: 400;
		text-align: center;
		padding: 5px;

		@include breakPoint($breakPointMob){
			padding: 10px;
		}

		@include breakPoint($breakPointTab1){
			text-align: left;
		}
	}
	td{
		text-align: left;
		font-family: $fontMain;
		border: 1px solid $colorBorder;
		padding: 5px;

		@include breakPoint($breakPointMob){
			padding: 10px;
		}

		@include breakPoint($breakPointDesk1){
			font-size: 13px;
		}
	}


	&__head{
		overflow: hidden;
		color: $colorGrey;
		padding: 5px 0 15px;
		font-family: $fontMain;

		&-title{
			float: left;
			font-size: 14px;

			@include breakPoint($breakPointMob){
				font-size: 24px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 16px;
			}
		}

		&-likes{
			position: relative;
			cursor: pointer;
			float: right;
			font-size: 0;
			margin-top: 4px;

			@include breakPoint($breakPointDesk1){
				font-size: 13px;
			}

			@include breakPoint($breakPointDesk2){
				display: none;
			}

			&:before{
				content: '';
				display: inline-block;
				vertical-align: middle;
				@include sprite-ico-like-mob-grey(true);
				margin-right: 5px;
				margin-top: -3px;

				.card__item_tag &,
				.card__item_big &{
					@include sprite-ico-like-mob-white;
				}

				@include breakPoint($breakPointMob){
					margin-right: 15px;
					margin-top: -5px;
					@include sprite-ico-like-tab-grey;

					.card__item_tag &,
					.card__item_big &{
						@include sprite-ico-like-tab-white;
					}
				}

				@include breakPoint($breakPointDesk1){
					margin-right: 5px;
					margin-top: -3px;
					@include sprite-ico-like-mob-grey;

					.card__item_tag &,
					.card__item_big &{
						@include sprite-ico-like-mob-white;
					}
				}
			}

			&:hover,
			&.is-active{
				color: $colorYellow;

				&:before{
					@include sprite-ico-like-mob-yellow;

					@include breakPoint($breakPointMob){
						@include sprite-ico-like-tab-yellow;
					}

					@include breakPoint($breakPointDesk1){
						@include sprite-ico-like-mob-yellow;
					}
				}
			}

		}
	}


	&__img,
	&__video{

		margin-bottom: 20px;
		font-family: $fontMain;
		max-width: 640px;

		img{
			width: 100%;
		}

		iframe, video{
			width: 100%;
			height: 100%;
		}



		@include breakPoint($breakPointMob){
			margin-bottom: 40px;
		}

		@include breakPoint($breakPointTab1){
			margin-bottom: 30px;
		}

		&-note{
			font-size: 14px;
			color: $colorGrey;
			border-bottom: 1px solid $colorBorder;
			padding: 8px 0;
			overflow: hidden;

			@include breakPoint($breakPointMob, $breakPointTab1 - 1px){
				font-size: 20px;
				padding: 15px 0;
			}

			@include breakPoint($breakPointTab1){
				padding: 12px 0;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 13px;
			}

			.name{
				float: left;
			}
			.time{
				float: right;
			}

		}

		&-player{
			height: 186px;
			position: relative;
			background: $colorYellow;

			@include breakPoint($breakPointMob){
				height: 373px;
			}

			@include breakPoint($breakPointTab1){
				height: 389px;
			}

			@include breakPoint($breakPointTab2){
				height: 391px;
			}

			@include breakPoint($breakPointDesk1){
				height: 358px;
			}

			@include breakPoint($breakPointDesk2){
				height: 385px;
			}

			@include breakPoint($breakPointDesk3){
				height: 498px;
			}
		}


		&-carousel{
			position: relative;
		}

		&_carousel{
			max-width: 100%;
		}

		&_main{
			img{
				max-width: 530px;
			}



			@include breakPoint($breakPointDesk3){
				overflow: hidden;

				img{
					float: left;
				}

				.article__img-note{
					margin-left: 550px;
					border: none;
				}
			}
		}
	}

	&__video{
		max-width: 100%;
	}

	&__files{
		background: #f6f6f6;
		padding: 25px 25px;
		margin-bottom: 20px;

		@include breakPoint($breakPointMob, $breakPointDesk1 - 1px){
			padding: 40px;
		}

		&-title{
			font-family: $fontMain;
			font-weight: 500;
			margin-bottom: 15px;

			@include breakPoint($breakPointMob){
				margin-bottom: 25px;
				font-size: 30px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 22px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 13px;
				margin-bottom: 15px;
			}
		}

		&-item{
			margin-bottom: 15px;

			@include breakPoint($breakPointMob){
				margin-bottom: 25px;
			}

			@include breakPoint($breakPointDesk1){
				margin-bottom: 15px;

				&:after{
					content: '';
					display: table;
					clear: left;
				}
			}
		}

		&-subtitle{
			position: relative;
			margin-bottom: 15px;

			@include breakPoint($breakPointDesk1){
				width: 210px;
				float: left;
				margin-bottom: 0;
			}

			&:before{
				content: '';
				position: absolute;
				@include sprite-ico-clip-mob-yellow(true);
				top: 3px;
				left: -32px;

				@include breakPoint($breakPointMob){
					@include sprite-ico-clip-mob2-yellow;
					top: 1px;
					left: -54px;
				}

				@include breakPoint($breakPointTab1){
					@include sprite-ico-clip-tab-yellow;
					top: 1px;
					left: -51px;
				}
				@include breakPoint($breakPointDesk1){
					@include sprite-ico-clip-mob-yellow;
					top: 3px;
					left: -32px;
				}
			}
		}
		&-list{

			@include breakPoint($breakPointDesk1){
				margin-left: 230px;
				font-size: 13px;
			}

			a{
				display: inline-block;
				font-family: $fontMain;
				margin: 0 15px 10px 0;
				border-bottom: 1px dashed $colorGrey;
				color: $colorGrey;

				&:hover{
					color: $colorYellow;
					border-bottom-color: transparent;
				}
			}
		}
		&-btn{
			.btn{
				display: block;
				font-size: 12px;
				padding: 8px 15px;

				@include breakPoint($breakPointMob){
					font-size: 24px;
					padding: 15px 20px;
				}

				@include breakPoint($breakPointTab1){
					font-size: 22px;
					padding: 12px 20px;
				}

				@include breakPoint($breakPointDesk1){
					display: inline-block;
					padding: 0;
					border: 0;
					border-bottom: 1px dashed $colorYellow;
					font-size: 13px;
					@include border-radius(0);

					&:hover{
						border-color: transparent;
						color: $colorYellow;
					}
				}
			}
		}
	}


	&__info{
		font-family: $fontMain;
		margin: 20px 0;

		@include breakPoint($breakPointMob){
			margin: 40px 0;
		}

		@include breakPoint($breakPointDesk1){
			font-size: 13px;
			border-bottom: 1px solid $colorBorder;
			margin: 0 0 20px;
			overflow: hidden;
			padding: 10px 0 20px;
		}

		@include breakPoint($breakPointDesk2){
			border: none;
		}

		&-item{
			overflow: hidden;
			margin-bottom: 10px;

			@include breakPoint($breakPointDesk1){
				width: 50%;
				float: left;
				margin-bottom: 0;
			}

			@include breakPoint($breakPointDesk2){
				float: none;
				width: auto;
				border-top: 1px solid $colorBorder;
				padding: 30px 0;
			}

			&:last-child{
				margin-bottom: 0;
			}
		}

		&-title{
			float: left;
			font-weight: 500;
			margin-bottom: 15px;
			color: $colorBlack;

			@include breakPoint($breakPointMob){
				font-size: 30px;
			}

			@include breakPoint($breakPointTab1){
				font-size: 22px;
			}

			@include breakPoint($breakPointDesk1){
				font-size: 13px;
			}

			@include breakPoint($breakPointDesk2){
				float: none;
				width: auto;
				margin-bottom: 10px;
			}
		}

		&-text{
			margin-left: 75px;
			color: $colorGrey;

			@include breakPoint($breakPointMob){
				margin-left: 120px;
				margin-top: 7px;
			}

			@include breakPoint($breakPointTab1){
				margin-left: 90px;
				margin-top: 7px;
			}

			@include breakPoint($breakPointDesk1){
				margin-top: 0px;
				margin-left: 60px;
			}

			@include breakPoint($breakPointDesk2){
				margin: 0;
			}

			.name{
				color: $colorBlack;
			}
			.rank{}
		}

		&-tags{
			margin-left: 75px;
			color: $colorGrey;

			@include breakPoint($breakPointMob){
				margin-left: 120px;
				margin-top: 7px;
			}

			@include breakPoint($breakPointTab1){
				margin-left: 90px;
				margin-top: 7px;
			}

			@include breakPoint($breakPointDesk1){
				margin-top: 0;
				margin-left: 60px;
			}

			@include breakPoint($breakPointDesk2){
				margin: 0;
			}

			a{
				display: inline-block;
				margin: 0 10px 0 0;

				&:before{
					content: '#';
					color: $colorYellow;
					margin-right: 5px;
				}

				@include breakPoint($breakPointDesk2){
					display: block;
					margin: 0 0 10px;
				}

				&:hover{
					color: $colorYellow;

				}
			}
		}
	}


	&__nav{
		overflow: hidden;
		border-top: 1px solid $colorYellow;
		color: $colorText;
		padding: 15px 0;


		@include breakPoint($breakPointMob){
			padding: 30px 0;
			border-top-width: 2px;
		}

		@include breakPoint($breakPointDesk1){
			font-size: 13px;
		}

		&-prev{
			@include box-sizing();
			float: left;
			width: 50%;
			padding: 0 25px;
			position: relative;
			cursor: pointer;

			max-width: 260px;

			&:hover{
				color: $colorYellow;
			}

			@include breakPoint($breakPointMob){
				padding: 0 40px;
			}
		}
		&-next{
			@include box-sizing();
			float: right;
			width: 50%;
			padding: 0 25px;
			position: relative;
			cursor: pointer;
			max-width: 260px;

			&:hover{
				color: $colorYellow;
			}

			@include breakPoint($breakPointMob){
				padding: 0 40px;
			}

		}
		&-direction{
			font-family: $fontMain;
			margin-bottom: 10px;


			&:before{
				content: '';
				@include sprite-ico-arrow-back-mob(true);
				position: absolute;
				top: 0;

				.article__nav-prev &{
					left: 0;

					@include breakPoint($breakPointMob){
						@include sprite-ico-arrow-back-mob2;
					}

					@include breakPoint($breakPointTab1){
						@include sprite-ico-arrow-back-tab;
					}

					@include breakPoint($breakPointDesk1){
						@include sprite-ico-arrow-back-mob
					}
				}

				.article__nav-prev:hover &{
					@include sprite-ico-arrow-back-mob-yellow;

					@include breakPoint($breakPointMob){
						@include sprite-ico-arrow-back-mob2-yellow;
					}

					@include breakPoint($breakPointTab1){
						@include sprite-ico-arrow-back-tab-yellow;
					}

					@include breakPoint($breakPointDesk1){
						@include sprite-ico-arrow-back-mob-yellow
					}
				}

				.article__nav-next &{
					@include sprite-ico-arrow-more-mob;
					right: 0;

					@include breakPoint($breakPointMob){
						@include sprite-ico-arrow-more-mob2;
					}

					@include breakPoint($breakPointTab1){
						@include sprite-ico-arrow-more-tab;
					}

					@include breakPoint($breakPointDesk1){
						@include sprite-ico-arrow-more-mob
					}
				}

				.article__nav-next:hover &{
					@include sprite-ico-arrow-more-mob-yellow;

					@include breakPoint($breakPointMob){
						@include sprite-ico-arrow-more-mob2-yellow;
					}

					@include breakPoint($breakPointTab1){
						@include sprite-ico-arrow-more-tab-yellow;
					}

					@include breakPoint($breakPointDesk1){
						@include sprite-ico-arrow-more-mob-yellow
					}
				}
			}


		}
		&-title{
		}

	}





	.ingredient{
		@include breakPoint($breakPointDesk1){
			overflow: hidden;
		}

		ul{

			li{
				margin: 10px 0;
				font-family: $fontMain;

				@include breakPoint($breakPointMob){
					margin: 20px 0;
				}
				@include breakPoint($breakPointDesk1){
					border-bottom: 1px solid $colorBorder;
					margin: 0;
					padding: 15px 0 15px 20px;
					font-size: 13px;

					&:before {
						top: 20px;
					}
				}
			}
		}

		&__list{
			font-family: $fontMain;
			@include breakPoint($breakPointDesk1){
				width: 180px;
				float: left;
			}
		}

		&__info{
			@include breakPoint($breakPointDesk1){
				margin-left: 200px;
			}
		}

		.print{
			display: none;

			@include breakPoint($breakPointDesk1){
				font-size: 13px;
				display: block;

				&:before{
					content: '';
					@include sprite-ico-clip-mob-yellow(true);
					display: inline-block;
					vertical-align: middle;
					margin-right: 5px;
				}

				a{
					display: inline-block;
					color: $colorYellow;
					border-bottom: 1px dashed $colorYellow;

					&:hover{
						border-bottom-color: transparent;
					}
				}
			}

		}
	}
}



.is-hidden{
	display: none !important;
}